import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { locationChanged } from '../redux/product/product.actions'

import Main from '../components/main';

const MainPage = ({ locationChanged, product_id = null, virtual_id = null, category_id = null }) => {

    const [dofloat, setFloat] = useState(false);
    const [imageMax, setImageMax] = useState(false);
    const [imageMed, setImageMed] = useState(false);

    useEffect(() => {
        const handleSize = () => {
            document.documentElement.style.setProperty('--window-width', `${window.innerWidth / 10}rem`); 
            let item_height = 0;
            if (window.innerWidth <= 460) {
                item_height = 27;
                if (window.innerHeight <= 365) item_height += 10;                
                document.documentElement.style.setProperty('--sort-mod', 1); 
                document.documentElement.style.setProperty('--product-mod', 1); 

                document.documentElement.style.setProperty('--item-width', '13rem');
                document.documentElement.style.setProperty('--item-height', `${item_height}vh`);
                document.documentElement.style.setProperty('--item-img-height', '11vh');
                document.documentElement.style.setProperty('--item-name-width', '90%');
                document.documentElement.style.setProperty('--item-name-font-size', '1rem');
                document.documentElement.style.setProperty('--item-grid-template', '33% 33% 33%'); 
                                
                document.documentElement.style.setProperty('--preview-table-image-height', '6rem');

                document.documentElement.style.setProperty('--product-name-size', '1.7rem');
                document.documentElement.style.setProperty('--product-price-size', '2rem');
                document.documentElement.style.setProperty('--product-virtual-size', '1.2rem');
                document.documentElement.style.setProperty('--product-description-size', '1.2rem');

                if (document.getElementsByClassName('loading-sign')[0].className.includes('--active')) {
                    document.getElementsByClassName('loading-sign')[0].className = 'loading-sign loading-sign--min loading-sign--active';
                }
                else document.getElementsByClassName('loading-sign')[0].className = 'loading-sign loading-sign--min';                
                
                document.getElementsByClassName('pnl-sort')[0].className = 'pnl-sort pnl-sort--active';
                if (document.getElementsByClassName('dir-product')[0] !== undefined) {
                    document.getElementsByClassName('dir-product')[0].className = 'dir-product dir-product--min';                    
                }                
                
                if (dofloat) setFloat(false);
                if (imageMed) setImageMed(false);
                if (imageMax) setImageMax(false);
            }
            else if (window.innerWidth <= 660) {
                item_height = 27;
                if (window.innerHeight <= 545) item_height += 10;  
                document.documentElement.style.setProperty('--sort-mod', 1); 
                document.documentElement.style.setProperty('--product-mod', 1); 

                document.documentElement.style.setProperty('--item-width', '13rem');
                document.documentElement.style.setProperty('--item-height', `${item_height}vh`);
                document.documentElement.style.setProperty('--item-img-height', '11vh');
                document.documentElement.style.setProperty('--item-name-font-size', '1.2rem');
                document.documentElement.style.setProperty('--item-grid-template', '33% 33% 33%'); 
                                
                document.documentElement.style.setProperty('--preview-table-image-height', '7rem');

                document.documentElement.style.setProperty('--product-name-size', '1.7rem');
                document.documentElement.style.setProperty('--product-price-size', '2rem');
                document.documentElement.style.setProperty('--product-virtual-size', '1.2rem');
                document.documentElement.style.setProperty('--product-description-size', '1.2rem');

                if (document.getElementsByClassName('loading-sign')[0].className.includes('--active')) {
                    document.getElementsByClassName('loading-sign')[0].className = 'loading-sign loading-sign--min loading-sign--active';
                }
                else document.getElementsByClassName('loading-sign')[0].className = 'loading-sign loading-sign--min';                            
                
                document.getElementsByClassName('pnl-sort')[0].className = 'pnl-sort pnl-sort--active';
                if (document.getElementsByClassName('dir-product')[0] !== undefined) {
                    document.getElementsByClassName('dir-product')[0].className = 'dir-product dir-product--min';                    
                }               
                
                if (dofloat) setFloat(false);
                if (imageMed) setImageMed(false);
                if (imageMax) setImageMax(false);
            }
            else if (window.innerWidth <= 985) {
                item_height = 20;
                if (window.innerHeight <= 545) item_height += 10; 
                document.documentElement.style.setProperty('--sort-mod', 1); 
                document.documentElement.style.setProperty('--product-mod', 1); 

                document.documentElement.style.setProperty('--item-width', '12rem');
                document.documentElement.style.setProperty('--item-height', `${item_height}vh`);
                document.documentElement.style.setProperty('--item-img-height', '9vh');
                document.documentElement.style.setProperty('--item-name-font-size', '1.1rem');  
                document.documentElement.style.setProperty('--item-grid-template', '25% 25% 25% 25%');
                                
                document.documentElement.style.setProperty('--preview-table-image-height', '12rem');

                document.documentElement.style.setProperty('--product-name-size', '1.7rem');
                document.documentElement.style.setProperty('--product-price-size', '2rem');
                document.documentElement.style.setProperty('--product-virtual-size', '1.2rem');
                document.documentElement.style.setProperty('--product-description-size', '1.2rem');

                if (document.getElementsByClassName('loading-sign')[0].className.includes('--active')) {
                    document.getElementsByClassName('loading-sign')[0].className = 'loading-sign loading-sign--min loading-sign--active';
                }
                else document.getElementsByClassName('loading-sign')[0].className = 'loading-sign loading-sign--min';                
                
                document.getElementsByClassName('pnl-sort')[0].className = 'pnl-sort pnl-sort--active';
                if (document.getElementsByClassName('dir-product')[0] !== undefined) {
                    document.getElementsByClassName('dir-product')[0].className = 'dir-product dir-product--min';                    
                }                  
                let closes = document.getElementsByClassName('dir-sort__close');
                for (let i = 0; i < closes.length; i++) {
                    if (closes[i].className.includes('--active')) closes[i].className = 'button-circle dir-sort__close';
                }              
                
                if (dofloat) setFloat(false);
                if (imageMed) setImageMed(false);
                if (imageMax) setImageMax(false);
            }
            else if (window.innerWidth <= 1130) {
                item_height = 20;
                if (window.innerHeight <= 545) item_height += 10; 
                document.documentElement.style.setProperty('--sort-mod', 1/5); 
                document.documentElement.style.setProperty('--product-mod', 3/5); 
                
                document.documentElement.style.setProperty('--item-width', '12rem');
                document.documentElement.style.setProperty('--item-height', `${item_height}vh`);
                document.documentElement.style.setProperty('--item-img-height', '9vh');
                document.documentElement.style.setProperty('--item-name-font-size', '1.1rem');
                document.documentElement.style.setProperty('--item-grid-template', '25% 25% 25% 25%');
                
                document.documentElement.style.setProperty('--preview-height', `${(window.innerWidth * (3/5) / 10) - 6}rem`);               
                document.documentElement.style.setProperty('--preview-table-image-height', '10rem');

                document.documentElement.style.setProperty('--product-name-size', '1.7rem');
                document.documentElement.style.setProperty('--product-price-size', '2rem');
                document.documentElement.style.setProperty('--product-virtual-size', '1.2rem');
                document.documentElement.style.setProperty('--product-description-size', '1.2rem');
                
                if (document.getElementsByClassName('loading-sign')[0].className.includes('--active')) {
                    document.getElementsByClassName('loading-sign')[0].className = 'loading-sign loading-sign--active';
                }
                else document.getElementsByClassName('loading-sign')[0].className = 'loading-sign';                

                document.getElementsByClassName('pnl-sort')[0].className = 'pnl-sort';
                if (document.getElementsByClassName('dir-product')[0] !== undefined) {
                    document.getElementsByClassName('dir-product')[0].className = 'dir-product';                    
                }                
                let closes = document.getElementsByClassName('dir-sort__close');
                for (let i = 0; i < closes.length; i++) {
                    if (closes[i].className.includes('--active')) closes[i].className = 'button-circle dir-sort__close';
                } 

                if (!dofloat) setFloat(true);
                if (!imageMed) setImageMed(true);
                if (imageMax) setImageMax(false);
            }
            else if (window.innerWidth <= 1360) {
                item_height = 20;
                if (window.innerHeight <= 620) item_height += 10; 
                document.documentElement.style.setProperty('--sort-mod', 1/5); 
                document.documentElement.style.setProperty('--product-mod', 3/5); 
                
                document.documentElement.style.setProperty('--item-width', '12rem');
                document.documentElement.style.setProperty('--item-height', `${item_height}vh`);
                document.documentElement.style.setProperty('--item-img-height', '9vh');
                document.documentElement.style.setProperty('--item-name-font-size', '1.1rem');
                document.documentElement.style.setProperty('--item-grid-template', '25% 25% 25% 25%');                                 

                document.documentElement.style.setProperty('--preview-height', `${(window.innerWidth * (3/5) / 10) - 6}rem`);               
                document.documentElement.style.setProperty('--preview-table-image-height', '12rem');

                document.documentElement.style.setProperty('--product-name-size', '1.7rem');
                document.documentElement.style.setProperty('--product-price-size', '2rem');
                document.documentElement.style.setProperty('--product-virtual-size', '1.2rem');
                document.documentElement.style.setProperty('--product-description-size', '1.2rem');

                if (document.getElementsByClassName('loading-sign')[0].className.includes('--active')) {
                    document.getElementsByClassName('loading-sign')[0].className = 'loading-sign loading-sign--active';
                }
                else document.getElementsByClassName('loading-sign')[0].className = 'loading-sign';   
                
                document.getElementsByClassName('pnl-sort')[0].className = 'pnl-sort';
                if (document.getElementsByClassName('dir-product')[0] !== undefined) {
                    document.getElementsByClassName('dir-product')[0].className = 'dir-product';                    
                }                
                let closes = document.getElementsByClassName('dir-sort__close');
                for (let i = 0; i < closes.length; i++) {
                    if (closes[i].className.includes('--active')) closes[i].className = 'button-circle dir-sort__close';
                } 

                if (!dofloat) setFloat(true);
                if (!imageMed) setImageMed(true);
                if (imageMax) setImageMax(false);
            }
            else if (window.innerWidth <= 1440) {
                item_height = 25;
                if (window.innerHeight <= 620) item_height += 10; 
                document.documentElement.style.setProperty('--sort-mod', 1/6); 
                document.documentElement.style.setProperty('--product-mod', 4/6);
                
                document.documentElement.style.setProperty('--item-width', '17rem');
                document.documentElement.style.setProperty('--item-height', `${item_height}vh`);
                document.documentElement.style.setProperty('--item-img-height', '12vh');
                document.documentElement.style.setProperty('--item-name-font-size', '1.5rem');
                document.documentElement.style.setProperty('--item-grid-template', '25% 25% 25% 25%'); 
                
                document.documentElement.style.setProperty('--preview-height', '49.55rem');
                document.documentElement.style.setProperty('--preview-table-height', '48rem');
                document.documentElement.style.setProperty('--preview-table-image-height', '12rem');

                document.documentElement.style.setProperty('--product-name-size', '2rem');
                document.documentElement.style.setProperty('--product-price-size', '2.3rem');
                document.documentElement.style.setProperty('--product-virtual-size', '1.4rem');
                document.documentElement.style.setProperty('--product-description-size', '1.4rem');

                if (document.getElementsByClassName('loading-sign')[0].className.includes('--active')) {
                    document.getElementsByClassName('loading-sign')[0].className = 'loading-sign loading-sign--active';
                }
                else document.getElementsByClassName('loading-sign')[0].className = 'loading-sign';   

                document.getElementsByClassName('pnl-sort')[0].className = 'pnl-sort';
                if (document.getElementsByClassName('dir-product')[0] !== undefined) {
                    document.getElementsByClassName('dir-product')[0].className = 'dir-product';                    
                }                
                let closes = document.getElementsByClassName('dir-sort__close');
                for (let i = 0; i < closes.length; i++) {
                    if (closes[i].className.includes('--active')) closes[i].className = 'button-circle dir-sort__close';
                } 

                if (!dofloat) setFloat(true);
                if (imageMed) setImageMed(false);
                if (!imageMax) setImageMax(true);
            }
            else {
                item_height = 25;
                if (window.innerHeight <= 620) item_height += 10; 
                document.documentElement.style.setProperty('--sort-mod', 1/8); 
                document.documentElement.style.setProperty('--product-mod', 6/8);
               
                document.documentElement.style.setProperty('--item-width', '17rem');
                document.documentElement.style.setProperty('--item-height', `${item_height}vh`);
                document.documentElement.style.setProperty('--item-img-height', '12vh');
                document.documentElement.style.setProperty('--item-name-font-size', '1.5rem'); 
                document.documentElement.style.setProperty('--item-grid-template', '25% 25% 25% 25%');  
                                
                document.documentElement.style.setProperty('--preview-height', '49.55rem');
                document.documentElement.style.setProperty('--preview-table-height', '48rem');
                document.documentElement.style.setProperty('--preview-table-image-height', '12rem');
                
                document.documentElement.style.setProperty('--product-name-size', '2rem');
                document.documentElement.style.setProperty('--product-price-size', '2.3rem');
                document.documentElement.style.setProperty('--product-virtual-size', '1.4rem');
                document.documentElement.style.setProperty('--product-description-size', '1.4rem');

                if (document.getElementsByClassName('loading-sign')[0].className.includes('--active')) {
                    document.getElementsByClassName('loading-sign')[0].className = 'loading-sign loading-sign--active';
                }
                else document.getElementsByClassName('loading-sign')[0].className = 'loading-sign';   
                
                document.getElementsByClassName('pnl-sort')[0].className = 'pnl-sort';
                if (document.getElementsByClassName('dir-product')[0] !== undefined) {
                    document.getElementsByClassName('dir-product')[0].className = 'dir-product';                    
                }               
                let closes = document.getElementsByClassName('dir-sort__close');
                for (let i = 0; i < closes.length; i++) {
                    if (closes[i].className.includes('--active')) closes[i].className = 'button-circle dir-sort__close';
                } 

                if (!dofloat) setFloat(true);
                if (imageMed) setImageMed(false);
                if (!imageMax) setImageMax(true);
            }            
        }
        handleSize();        
        window.addEventListener('resize', handleSize);
        window.addEventListener('popstate', () => window.history.go(0));
        return _=> {
            window.removeEventListener('resize', handleSize);
            window.removeEventListener('popstate', () => () => window.history.go(0));
        }
    })   

    return (
        <div className='main-page'>            
            <Main imageMed={imageMed} imageMax={imageMax} product_id={product_id} virtual_id={virtual_id} category_id={category_id} dofloat={dofloat}/>
        </div>
    ); 
}
const mapDispatchToProps = dispatch => ({
    locationChanged: () => dispatch(locationChanged())
});
export default connect(null, mapDispatchToProps)(MainPage);