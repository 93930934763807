import React from 'react';

import ButtonCircle from './button-circle';

const PopUpForm = ({ popUpInput }) => (    
    <div className='pop-up-form' style={{ display: popUpInput.display === true ? 'block' : 'none' }} >        
        <span className='pop-up-form__static'>{popUpInput.static}</span>
        {popUpInput.item !== null
        ? <span className='pop-up-form__item'>{popUpInput.item}</span>
        : null}
        <ButtonCircle className='pop-up-form__btn-no' value='Nem' onClick={popUpInput.onNo} />
        <ButtonCircle className='pop-up-form__btn-yes' value='Igen' onClick={popUpInput.onYes} />
    </div>
)
export default PopUpForm;