import React, { useState } from 'react';

import { connect } from 'react-redux';
import { logged } from '../redux/product/product.actions';
import { delay } from '../utils';

const Navbar = ({ logged, doUpdate, setStateOwn }) => {    
    
    const [isDisplayed, setDisplay] = useState(false);    

    const refMenuSide = React.createRef();
    const refMenu = React.createRef();

    const animateMovement = async () => {
        refMenu.current.style.display = 'none';        
        if (!isDisplayed) {
            refMenuSide.current.className = 'navbar__menu-side navbar__menu-side--active';
            let i = 0;
            let percent = 0.25;
            if (window.innerWidth < 800) percent = 0.40;
            while (i < window.innerWidth * percent) {
                refMenuSide.current.style.width = `${i}px`;
                if (window.innerWidth >= 800) refMenu.current.style.right = `${i - 90}px`;
                await delay(10);
                i += 10;
            }
            refMenu.current.style.display = 'block'; 
            setDisplay(true);            
        }
        else {
            let i = refMenuSide.current.style.width.substr(0, Number(refMenuSide.current.style.width.length - 2));
            while (i >= 0) {
                refMenuSide.current.style.width = `${i}px`;
                if (window.innerWidth >= 800) refMenu.current.style.right = `${10 + i}px`;
                await delay(10);
                i -= 10;
            }
            refMenuSide.current.className = 'navbar__menu-side';
            refMenu.current.style.display = 'block'; 
            setDisplay(false);            
        }
    }

      

    const openVirtualLib = () => {                
        doUpdate();
        setStateOwn(s => ({...s, virtualLib: true}));        
    }

    const openPanelInfo = () => {
        doUpdate();
        setStateOwn(s => ({...s, panelInfo: true}));  
    }

    return (
        <div className='navbar'>
            <div ref={refMenuSide} className='navbar__menu-side'>
            <div className='navbar__menu-side__option' onClick={openPanelInfo}>
                    Információk
                </div>
                <div className='navbar__menu-side__option' onClick={openVirtualLib}>
                    Tulajdonságok
                </div>
                <div className='navbar__menu-side__option' onClick={() => logged(false)}>
                    Kijelentkezés
                </div>
            </div>            
            <div ref={refMenu} className='navbar__menu' onClick={() => animateMovement()}> 
                {isDisplayed ? <>&#x2192;</> : <>&#x2630;</>}                               
            </div>
        </div>
    );
}
const mapDispatchToProps = dispatch => ({
    logged: v => dispatch(logged(v))
});
export default connect(null, mapDispatchToProps)(Navbar);