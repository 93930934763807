import React, { useState, useEffect } from "react";
import { resources } from "../axios";

const ProductPreviewCustomer = ({ med, min, images }) => {

    const ZOOM = 3;
    const MANGNIFIER_SIZE = 300;
    const refMagnifier = React.createRef();
    const refImg = React.createRef();

    const [idx, setIdx] = useState(0);
    const [showMagnify, setMagnify] = useState('none');

    useEffect(() => {        
        refMagnifier.current.style.backgroundImage = `url(${resources + images[idx].name})`;
        refMagnifier.current.style.backgroundRepeat = 'no-repeat';
        refMagnifier.current.style.backgroundSize = `${refImg.current.offsetWidth * ZOOM}px ${refImg.current.offsetHeight * ZOOM}px`
        refMagnifier.current.style.width = `${MANGNIFIER_SIZE}px`;
        refMagnifier.current.style.height = `${MANGNIFIER_SIZE}px`;
    })

    const startMagnify = e => {
        if (window.innerWidth > 985) {
            setMagnify('block');
            var pos, x, y;
            pos = getCursorPos(e);
            x = pos.x;
            y = pos.y;             
            refMagnifier.current.style.left = `${x - MANGNIFIER_SIZE / 2}px`;
            refMagnifier.current.style.top = `${y - MANGNIFIER_SIZE / 2}px`;
        }        
    }

    const stopMagnify = () => {
        if (window.innerWidth > 985) {
            setMagnify('none');
        }        
    }

    const moveMagnifier = e => {
        if (window.innerWidth > 985) {
            //e.preventDefault();          
            var pos, x, y, bw, w, h;    
            bw = 3;
            w = MANGNIFIER_SIZE / 2;
            h = MANGNIFIER_SIZE / 2;                 
            pos = getCursorPos(e);
            x = pos.x;
            y = pos.y;        
            console.log('x: ' + x);
            console.log('y: ' + y);        
            if (x > refImg.current.offsetWidth - (w / ZOOM)) x = refImg.current.offsetWidth - (w / ZOOM);
            if (x < w / ZOOM) x = w / ZOOM;
            if (y > refImg.current.offsetHeight - (h / ZOOM)) y = refImg.current.offsetHeight - (h / ZOOM);
            if (y < h / ZOOM) y = h / ZOOM;

            refMagnifier.current.style.left = `${x - w}px`;
            refMagnifier.current.style.top = `${y - h}px`;

            refMagnifier.current.style.backgroundPosition = `-${((x * ZOOM) - w + bw)}px -${((y * ZOOM) - h + bw)}px`;
        }
        
    }

    const getCursorPos = e => {
        var a, x = 0, y = 0;               
        a = refImg.current.getBoundingClientRect();
        if (e.type === 'mousemove' || e.type === 'mouseenter') {
            x = e.pageX - a.left;
            y = e.pageY - a.top;  
        }        
        else if (e.type === 'touchmove') {                                       
            x = e.changedTouches[0].pageX - a.left;
            y = e.changedTouches[0].pageY - a.top;  
        }                  
        x = x - window.pageXOffset;
        y = y - window.pageYOffset;
        return {x : x, y : y};
    }

    return (
        <div className={`product-preview-customer${med ? ' product-preview-customer--med' : !min ? ' product-preview-customer--min' : ''}`}>
            <div className={`product-preview-customer__table${!min ? ' product-preview-customer__table--min' : ''}`}>
                {images.map((img, i)=> (
                    <div key={i} className={`product-preview-customer__table__box${!min ? ' product-preview-customer__table__box--min' : ''}`} onClick={() => setIdx(i)}>
                        <img className={`product-preview-customer__table__box__image${!min ? ' product-preview-customer__table__box__image--min' : ''}`} alt={img.name} src={resources + img.name}/>
                    </div>
                ))}
            </div>
            {images.length !== 0 ?
            <div className={`product-preview-customer__box${!min ? ' product-preview-customer__box--min' : ''}`} onMouseEnter={startMagnify} onMouseLeave={stopMagnify}>
                <img ref={refImg} className={`product-preview-customer__box__image${med ? ' product-preview-customer__box__image--med' : !min ? ' product-preview-customer__box__image--min' : ''}`} alt={images[idx].name} src={resources + images[idx].name}/>
                <div ref={refMagnifier} className='product-preview-customer__box__magnifier'
                    onMouseMove={moveMagnifier}
                    onTouchMove={moveMagnifier}
                    onTouchStart={() => console.log('start')}
                    onTouchEnd={() => console.log('end')}
                style={{
                    display: showMagnify                    
                }}/>
            </div>
            : null}
        </div>
    );
}
export default ProductPreviewCustomer;