import React from "react";

import ButtonBlack from "./button-black";
import { delay } from "../utils";

const PanelSort = () => {
    
    const dropDown = async idx => {
        document.getElementsByClassName('dir-sort')[idx].className = 'dir-sort dir-sort--left dir-sort--min dir-sort--active';               
        for (let i = 0; i <= 100; i+=10) {
            document.getElementsByClassName('dir-sort')[idx].style.clipPath = `polygon(0 0, 100% 0, 100% ${i}%, 0 ${i}%)`;
            await delay(10);
        }               
        document.getElementsByClassName('dir-sort')[idx].style.clipPath = 'none';
        document.getElementsByClassName('dir-sort__close')[idx].className = 'button-circle dir-sort__close dir-sort__close--active';               
    }

    return (
        <div className='pnl-sort'>
            <ButtonBlack className='pnl-sort__btn' value='Kategóriák' onClick={() => dropDown(0)}/>
            <ButtonBlack className='pnl-sort__btn' value='Szűrő' onClick={() => dropDown(1)}/>
        </div>
    );
}
export default PanelSort;