import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';

import { connect } from 'react-redux';
import { clearState } from './redux/product/product.actions';

import LoginPage from './pages/login.page';
import Dashboard from './pages/dashboard';
import MainPage from './pages/main.page';

const Routing = ({ isLogged, isLocationChanged, clearState }) => {

    const [urlParams, setUrlParams] = useState(new URLSearchParams(window.location.search));
  
    useEffect(() => {
        setUrlParams(new URLSearchParams(window.location.search));
    }, [isLocationChanged])    
        
      if (urlParams.has('page'))  {
        switch(urlParams.get('page')) {
          case '5556be48ef4d0f3aef568b3a46ce1b93-login':
            return <LoginPage />

          case 'admin-dashboard':
            if (isLogged === true) return <Dashboard />
            else {
              return <span>THIS PAGE IS UNAVAILABLE</span>
            }

          case 'browser':
          case 'product':
          case 'info':
            return <MainPage 
              virtual_id={urlParams.has('virtual-id') ? urlParams.get('virtual-id') : null}
              category_id={urlParams.has('category-id') ? urlParams.get('category-id') : null}
              product_id={urlParams.has('product-id') ? urlParams.get('product-id') : null}              
            />               

          case 'clear-commandef568b3a46ce1b93-state':
            clearState();
            return <span>CLEAN</span>

          default: break;
        }
      } 
      
      return <MainPage />         
}
const mapStateToProps = state => ({
    isLogged: state.product.isLogged,
    isLocationChanged: state.product.isLocationChanged
})
const mapDispatchToProps = dispatch => ({      
    clearState: () => dispatch(clearState())
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Routing));