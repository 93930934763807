export const ProductActionTypes = {   
    SET_PROG: 'SET_PROG',       

    IS_LOCATION_CHANGED: 'IS_LOCATION_CHANGED',
    IS_LOGGED: 'IS_LOGGED',

    OPEN_MAIN: 'OPEN_MAIN',
    OPEN_SUB: 'OPEN_SUB',


    CLEAR_OPENED: 'CLEAR_OPENED',
    CLEAR_STATE: 'CLEAR_STATE'
}