import React, { useEffect, useCallback } from 'react';

import { connect } from 'react-redux';

import axios, { resources } from '../axios';

import { v4 as uuidv4 } from 'uuid';
import { shorten } from '../utils';

import ButtonCircle from './button-circle';

const ProductLib = ({ sideSwitch, doUpdate, openedMain, openedSub, setStateOwn, products, categories, isSketch, splitNumber }) => {            

    const getProducts = useCallback(
        () => {           
            const data = new FormData();
            data.append('main_id', openedMain.id);
            data.append('sub_id', openedSub.id);
            if (isSketch) data.append('mod',0);
            else data.append('mod',1);
            axios.post('get-products.php', data)
            .then(res => {
                console.log(res);
                const products = res.data.products;
                const categories = res.data.categories;
                let p = [];
                for (let i = 0; i < products.length; i++) {
                    let virtCategs = products[i].category_virtual.split(';');
                    let virtProps = [];
                    for (let j = 0; j < virtCategs.length; j++) {
                        let props_temp = virtCategs[j].split('#');
                        virtProps = [...virtProps, {name: props_temp[0], value: props_temp[1]}];
                    }
                    p = [...p, {...products[i], category_virtual: virtProps}];
                }                
                setStateOwn(state => ({...state, products: p, categories: categories}));
            });                              
        },
        [openedMain.id, openedSub.id, isSketch, setStateOwn],
    )

    const clickDeleteProduct = id => {        
        let i = 0;
        while (i < products.length && products[i].id !== id) i++;
        doUpdate();
        setStateOwn(s => ({...s, popUpInput: {
            static: 'Biztosan szeretné törölni?',
            item: `termék: ${products[i].name}`,
            display: true,
            onNo: () => setStateOwn(s => ({...s, popUpInput: {...s.popUpInput, display: false}})),
            onYes: () => handleDeleteProduct(id)
        }}));
    }

    const handleDeleteProduct = id => {       
        const data = new FormData();
        data.append('id', id);
        data.append('ids', JSON.stringify({ main_id: openedMain.id, sub_id: openedSub.id }));
        axios.post('delete-product.php', data)
        .then(res => {
            console.log(res);
            setStateOwn(s => ({...s, popUpInput: {...s.popUpInput, display: false}}));
            getProducts();                       
        });
    }

    useEffect(() => {
        getProducts();        
    },[isSketch, getProducts]);

    const addNew = e => { 
        doUpdate();      
        setStateOwn(state => ({...state, images: [], virtualCategories: [], activeProduct: { id: uuidv4(), name: '', main_id: openedMain.id, sub_id: openedSub.id, price: 0, description: '' }, isActive: true}));
    }

    const addChange = id => {
        const data = new FormData();
        data.append('id', id);
        axios.post('get-one-product.php', data)
        .then(res => {
            console.log(res);
            doUpdate();
            setStateOwn(state => ({...state, activeProduct: res.data.product, images: res.data.images, virtualCategories: res.data.virtCategs, isActive: true}));
        })
    }

    const update = id => {
        const data = new FormData();
        data.append('id', id);
        if (isSketch) data.append('mod', 1);
        else data.append('mod', 0);
        axios.post('update-product.php', data)
        .then(res => {
            console.log(res);
            getProducts();
        })
    } 
    
    const recommend = (id, reco) => {  
        const data = new FormData();
        data.append('id', id);
        if (reco === '0') data.append('value', '1');
        else data.append('value', '0');
        axios.post('recommend-product.php', data)
        .then(res => {
            console.log(res);
            getProducts();
        })      
    }

    const switchWindow = () => {
        if (sideSwitch === 'img') {
            document.getElementsByClassName('category-side-lib')[0].style.width = '0';
            document.getElementsByClassName('category-side-lib__info-header__set')[0].style.display = 'none';
            document.getElementsByClassName('product-lib')[0].style.width = '100vw';
            document.getElementsByClassName('product-lib__add')[0].style.display = 'block';
            document.getElementsByClassName('product-lib__other')[0].style.display = 'block';
            setStateOwn(s => ({...s, sideSwitch: 'product'}));
        }
        else if (sideSwitch === 'product') {
            document.getElementsByClassName('category-side-lib')[0].style.width = '100vw';
            document.getElementsByClassName('category-side-lib__info-header__set')[0].style.display = 'block';
            document.getElementsByClassName('product-lib')[0].style.width = '100vw';
            document.getElementsByClassName('product-lib__add')[0].style.display = 'none';
            document.getElementsByClassName('product-lib__other')[0].style.display = 'none';
            setStateOwn(s => ({...s, sideSwitch: 'img'}));
        }
    }

    const moveUp = i => {
        setStateOwn(s => ({...s, products: s.products.map((pro, idx) => idx === i || idx === i - 1
            ? idx === i
                ? {...s.products[i - 1], queue_number: s.products[i].queue_number}
                : {...s.products[i], queue_number: s.products[i - 1].queue_number}
            : pro)}));
        const data = new FormData();
        data.append('id_one', products[i].id);
        data.append('id_two', products[i - 1].id);
        axios.post('switch-product.php', data)
        .then(res => {
            console.log(res);
        })
    }

    const moveDown = i => {
        setStateOwn(s => ({...s, products: s.products.map((pro, idx) => idx === i || idx === i + 1
            ? idx === i
                ? {...s.products[i + 1], queue_number: s.products[i].queue_number}
                : {...s.products[i], queue_number: s.products[i + 1].queue_number}
            : pro)}));
        const data = new FormData();
        data.append('id_one', products[i].id);
        data.append('id_two', products[i + 1].id);
        axios.post('switch-product.php', data)
        .then(res => {
            console.log(res);
        })
    }

    return (
        <div className='product-lib'>               
            <div className='product-lib__header'>
                <div className='product-lib__header__category'>
                    <span className='product-lib__header__category__main'>{`Főkategória: `}<strong>{categories.main}</strong></span>
                    <span className='product-lib__header__category__sub'>{`Alkategória: `}<strong>{categories.sub}</strong></span>
                </div>
                <span className='product-lib__header__title'>{isSketch ? 'Vázlatok' : 'Termékek'}</span>
            </div>            
            <ButtonCircle className='product-lib__add' onClick={addNew} value='+'/>
            <ButtonCircle className='product-lib__other' onClick={() => { doUpdate(); setStateOwn(state => ({...state, isSketch: !isSketch, products: []}))}} value={isSketch ? 'T' : 'V'}/>            
            <ButtonCircle className='product-lib__switch' onClick={switchWindow} value={sideSwitch === 'img' ? <>&rarr;</> : <>&larr;</>}/>
            <div className='product-lib__container'>
                {products !== undefined ? products.map((product, i) => (
                    <div key={i} className={`product-item${isSketch ? ' product-item--sketch' : ''}`}>
                        <div className='product-item__col-menu'>
                            <label className='product-item__col-menu__mod' onClick={() => addChange(product.id)}>Módosítás</label>                         
                            <label className='product-item__col-menu__del' onClick={() => clickDeleteProduct(product.id)}>Törlés</label>
                            <label className='product-item__col-menu__off' style={{ color: isSketch ? 'rgb(0, 200, 0)' : 'crimson' }} onClick={() => update(product.id)}>{isSketch ? 'Bekapcsolás' : 'Kikapcsolás'}</label>
                            {!isSketch
                            ? <label className='product-item__col-menu__reco' style={{ color: product.recommend === '0' ? 'rgb(0, 50, 250)' : 'rgb(0, 200, 0)' }} onClick={() => recommend(product.id, product.recommend)}>{product.recommend === '0' ? 'Ajánlás' : 'Ajánlva'}</label>
                            : null}
                        </div>
                        <div className='product-item__col-left'>
                            <div className='product-item__col-left__box'>                                
                                <img className='product-item__col-left__box__image' alt='termék kép' src={product.image_name.substr(0, 4) !== 'http' ? resources + product.image_name : product.image_name}/>
                            </div>
                        </div>
                        <div className='product-item__col-right'>
                            <div className='product-item__col-right__row-0'>
                                <span className='product-item__col-right__row-0__name'>{shorten(product.name, 35)}</span>
                                <span className='product-item__col-right__row-0__price'>{`${splitNumber(product.price)} Ft`}</span>
                            </div>
                            <div className='product-item__col-right__row-1'>                                                                
                                <div className='product-item__col-right__row-1__category-virtual-container'>
                                    {product.category_virtual.map((virtual, j) => j < 3                                        
                                        ? (<div key={j} className='product-item__col-right__row-1__category-virtual-container__item'>{`${virtual.name}:  `}<strong>{virtual.value}</strong></div>)
                                        : j === 3
                                        ? (<div key={j} className='product-item__col-right__row-1__category-virtual-container__item--dots'>...</div>)
                                        : null
                                    )}
                                </div>  
                                <span className='product-item__col-right__row-1__desc'>{shorten(product.description, 100)}</span>                                  
                                {!isSketch ?
                                    <div className='product-item__col-right__row-1__move'>
                                    {i !== 0 ?
                                        <ButtonCircle className='product-item__col-right__row-1__move__up' onClick={() => moveUp(i)} value='▲'/>
                                    : null}
                                    {i !== products.length - 1 ?
                                        <ButtonCircle className='product-item__col-right__row-1__move__down' onClick={() => moveDown(i)} value='▼'/>
                                    : null}                            
                                    </div>
                                : null}
                            </div>                                                         
                        </div>
                    </div>
                )) : null}
            </div>
        </div>
    );
}
const mapStateToProps = state => ({    
    openedMain: state.product.openedMain,
    openedSub: state.product.openedSub
});
export default connect(mapStateToProps)(ProductLib);