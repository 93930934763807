import CryptoJS from "crypto-js";

export const encrypt = (msg) => {
    let key = 'CUCCLI';
    let keyMD5 = CryptoJS.MD5(key).toString();
    let array = CryptoJS.enc.Utf8.parse(CryptoJS.TripleDES.encrypt(msg, keyMD5).toString());
    let crypt = CryptoJS.enc.Base64.stringify(array);
    return crypt;
}

export const decrypt = (msg) => {
    let key = 'CUCCLI';
    let keyMD5 = CryptoJS.MD5(key).toString(); 
    let array = CryptoJS.enc.Base64.parse(msg);
    let raw = CryptoJS.enc.Utf8.stringify(array);   
    let decrypt = CryptoJS.TripleDES.decrypt(raw, keyMD5).toString(CryptoJS.enc.Utf8);
    return decrypt;
}

export const splitNumber = n => {
    n = n.toString();
    let s = '';
    let idxs = [];
    switch (n.length) {
        case 4: 
            idxs.push(0);
            break;

        case 5:
            idxs.push(1);
            break;

        case 6:
            idxs.push(2);                
            break;

        case 7:
            idxs.push(0);
            idxs.push(3);
            break;

        case 8:
            idxs.push(1);
            idxs.push(4);
            break;

        default: break;
    }
    for (let i = 0; i < n.length; i++) {
        s += n[i].toString();
        let j = 0;
        while (j < idxs.length && idxs[j] !== i) j++;
        if (j < idxs.length) s += ' ';
    }
    console.log(s);
    return s;
}

export const shorten = (text, max) => {
    let s = '';            
    for (let i = 0; i < text.length && i < max; i++) {
        s += text[i];
    }
    if (text.length > max) s += '...';
    return s;
}

export const delay = ms => {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve(2);
      }, ms);
    });
}

export const decodePlain = text => {
    let res = [];
    let isOk = true;
    const array = text.split(/\n/);        
    for (let i = 0; i < array.length; i++) {
        if (array[i].includes('<')) {
            let text = '';
            let style = '';
            let isText = false;                
            for (let j = 0; j < array[i].length; j++) {
                if (array[i][j] === '<') isText = false;
                else if (array[i][j] === '>') isText = true;
                else if (array[i][j] !== '{' && array[i][j] !== '}' && isText) text += array[i][j];
                else if (!isText) style += array[i][j];
            }
            let style_array = style.split(' ');
            let style_pre = { display: 'block', cursor: 'unset' };
            for (let j = 0; j < style_array.length; j++) {
                let ok = false;
                if (style_array[j] === 'középre') {
                    style_pre = {...style_pre, textAlign: 'center'};
                    ok = true;  
                }
                else if (style_array[j] === 'balra') {
                    style_pre = {...style_pre, textAlign: 'left'};
                    ok = true;  
                }
                else if (style_array[j] === 'jobbra') {
                    style_pre = {...style_pre, textAlign: 'right'};
                    ok = true;  
                }                    
                else if (!isNaN(style_array[j]) && !isNaN(parseFloat(style_array[j]))) {
                    style_pre = {...style_pre, fontSize: `${Number(style_array[j]) / 10}rem`};
                    ok = true;
                }
                else if (style_array[j] === 'félkövér'){
                    style_pre = {...style_pre, fontWeight: '700'};
                    ok = true;
                }
                else if (style_array[j] === 'dőlt'){
                    style_pre = {...style_pre, fontStyle: 'italic'};
                    ok = true;
                }   
                else if (style_array[j] === 'egybe') {
                    style_pre = {...style_pre, display: 'inline-block'};
                    ok = true;
                } 
                else if (style_array[j].includes('térköz')) {
                    let ter = style_array[j].split('-');
                    if (ter.length === 2 && ter[0] === 'térköz' && !isNaN(ter[1]) && !isNaN(parseFloat(ter[1]))) {
                        style_pre = {...style_pre, padding: `${Number(ter[1]) / 10}rem`};
                        ok = true;
                    }
                    else if (ter.length === 3 && ter[0] === 'térköz' && !isNaN(ter[2]) && !isNaN(parseFloat(ter[2]))) {
                        if (ter[1] === 'fent') {
                            style_pre = {...style_pre, paddingTop: `${Number(ter[2]) / 10}rem`};
                            ok = true;
                        }
                        if (ter[1] === 'lent') {
                            style_pre = {...style_pre, paddingBottom: `${Number(ter[2]) / 10}rem`};
                            ok = true;
                        }
                        if (ter[1] === 'jobb') {
                            style_pre = {...style_pre, paddingRight: `${Number(ter[2]) / 10}rem`};
                            ok = true;
                        }
                        if (ter[1] === 'bal') {
                            style_pre = {...style_pre, paddingLeft: `${Number(ter[2]) / 10}rem`};
                            ok = true;
                        }
                    }
                }                                   
                
                if (!ok) {
                    isOk = false;
                    break;
                }
            }

            res = [...res, {text: text, style: style_pre}];
        }
    }
    return {res: res, isOk: isOk};
}