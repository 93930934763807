import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';

import axios from '../axios';

import { connect } from 'react-redux';
import { logged } from '../redux/product/product.actions';

const Login = ({ history, logged }) => {

    const INITIAL_STATE = {
        username: '',
        password: '',  
        error: false      
    }

    const [state, setState] = useState(INITIAL_STATE);      

    const handleChange = e => {       
        setState(s => ({...s, [e.target.name]: e.target.value}));
    }

    const handleLogin = () => {        
        const data = new FormData();        
        data.append('username', state.username);
        data.append('password', state.password);
        axios.post('login.php', data)        
        .then(res => {
            console.log(res);
            if (res.data.login === 'success') {                
                history.replace({ pathname: '/', search: '?page=admin-dashboard'});  
                logged(true);              
            }
            else {
                setState(s => ({...s, error: true}));               
            }
        });
    }

    return (
        <div className='login'>            
            <input name='username' className='login__username' type='text' placeholder='Felhasználónév' value={state.username} onChange={handleChange}/>
            <input name='password' className='login__password' type='password' placeholder='Jelszó' value={state.password} onChange={handleChange}/>
            <input className='login__btn' readOnly value='Bejelentkezés' onClick={() => handleLogin()}/>            
            {state.error === true
            ? <label className='login__msg'>Hibás felhasználónév vagy jelszó!</label>
            : null}            
        </div>
    );
}
const mapDispatchToProps = dispatch => ({
    logged: v => dispatch(logged(v))   
});
export default withRouter(connect(null, mapDispatchToProps)(Login));