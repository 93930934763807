import React from 'react';

import { encrypt, delay } from '../utils';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { locationChanged } from '../redux/product/product.actions'

import ButtonBlack from './button-black';

const ItemCategorySub = ({ tag, min, triggerProductBoth, triggerProductList, category, float, category_id, history, locationChanged }) => {
    
    const click = async e => { 
        if (!min) {
            let sorts = document.getElementsByClassName('dir-sort');
            for (let s = 0; s < sorts.length; s++) {
                if (sorts[s].className.includes('--active')) {                        
                    for (let i = 100; i >= 0; i-=10) {
                        sorts[s].style.clipPath = `polygon(0 0, 100% 0, 100% ${i}%, 0 ${i}%)`;
                        await delay(10);
                    }
                    sorts[s].style.clipPath = 'none';
                    sorts[s].className = 'dir-sort dir-sort--left dir-sort--min';  
                    document.getElementsByClassName('dir-sort__close')[s].className = 'button-circle dir-sort__close';               
                }
            }            
        }
        const crypted = encrypt(e.target.dataset.id);       
        if (tag === 'category') {            
            //history.replace({ pathname: '/', search: `?page=browser&&category-id=${crypted}` });
            //window.location.hash = encrypt(window.location.href);
            //e.preventDefault();
            history.push(`?page=browser&&category-id=${crypted}`);
            locationChanged();
            triggerProductList();
        }
        else {
            if (category_id !== null) {               
                //history.replace({ pathname: '/', search: `?page=browser&&category-id=${category_id}&&virtual-id=${crypted}` });
                //window.location.hash = encrypt(window.location.href);
                //e.preventDefault();
                history.push(`?page=browser&&category-id=${category_id}&&virtual-id=${crypted}`);
                locationChanged();
                triggerProductBoth();
            }
            else {                
                //history.replace({ pathname: '/', search: `?page=browser&&virtual-id=${crypted}` });
                //window.location.hash = encrypt(window.location.href);
                //e.preventDefault();
                history.push(`?page=browser&&virtual-id=${crypted}`);
                locationChanged();
                triggerProductList();
            }
        }                         
    }
    
    return (        
        <div className='item-category-sub'>            
            <ButtonBlack id={category.id} onClick={click} className={`item-category-sub__name
                ${float === 'left' ? ' item-category-sub__name--left' : float === 'right' ? ' item-category-sub__name--right' : ''}
                ${!min ? ' item-category-sub__name--min' : ''}
            `} value={category.name}/>
        </div>
    )
}
const mapDispatchToProps = dispatch => ({
    locationChanged: () => dispatch(locationChanged())
});
export default withRouter(connect(null, mapDispatchToProps)(ItemCategorySub));