import React from "react";

const ItemCategoryVirtual = ({ virtual, onClick }) => (
    <div className='item-category-virtual'>
        <span className='item-category-virtual__data'>
            {`${virtual.name}: `}
            <span onClick={onClick} className='item-category-virtual__data__value'>{virtual.value}</span>
        </span>
    </div>
)
export default ItemCategoryVirtual;