import { ProductActionTypes } from "./product.types";

const INITIAL_STATE = {       
    isLocationChanged: false,
    isLogged: false,

    prog: 'main',
    
    openedMain:{
        id: null
    },
    openedSub: {
        id: null
    }
}

const productReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {        
        case ProductActionTypes.SET_PROG:
            return {
                ...state,
                prog: action.value
            }        

        case ProductActionTypes.IS_LOCATION_CHANGED:
            return {
                ...state,
                isLocationChanged: !state.isLocationChanged
            }

        case ProductActionTypes.IS_LOGGED:
            return {
                ...state,
                isLogged: action.value,
                isLocationChanged: !state.isLocationChanged
            }

        case ProductActionTypes.OPEN_MAIN:
            return {
                ...state,
                prog: 'sub',
                openedMain: action.value
            }

        case ProductActionTypes.OPEN_SUB:
            return {
                ...state,
                prog: 'img',
                openedSub: action.value
            }

        case ProductActionTypes.CLEAR_OPENED:
            return {
                ...state,
                openedMain: INITIAL_STATE.openedMain,
                openedSub: INITIAL_STATE.openedSub,
                prog: 'main'
            }

        case ProductActionTypes.CLEAR_STATE:
            return {
                ...INITIAL_STATE
            }
        
        default: return state;
    }
}
export default productReducer;