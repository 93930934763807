import React, { useEffect, useState } from 'react';
import { decodePlain } from '../utils';
import { connect } from "react-redux";
import { locationChanged } from "../redux/product/product.actions";
import { withRouter } from "react-router-dom";
import axios from '../axios';

import ButtonBlack from './button-black';

const Information = ({ locationChanged, history, min, category_id, virtual_id, product_id, triggerProductReco, triggerProductList, triggerProductBoth }) => {

    //const TEXT = '<15 középre félkövér térköz-10>{Információk}\n<12 egybe térköz-bal-10>{Elérhetőség:}\n<12 egybe dőlt térköz-bal-5>{mazsolabox@gmail.com}\n<12 térköz-fent-30 térköz-bal-10 térköz-lent-6>{Leírás:}\n<12 térköz-bal-10>{Itt egy nagyon hosszú leírás minden féle cuccliról aztán jön a sor vége itt.}\n<12 térköz-bal-10>{De ez így kezelhető egy nagy szövegként. Csak a térközre kell odafigyelni.}';

    const [info, setInfo] = useState([]);

    useEffect(() => {
        axios.get('get-info-description.php')
        .then(res => {
            setInfo(decodePlain(res.data.info_description).res);         
        });        
    },[]);

    const click = (h, trigger) => {
        history.replace(h);
        locationChanged();  
        if(trigger !== null) trigger(); 
    }

    const back = () => {
        let search = '';        
        search += product_id !== null ? '?page=product' : category_id !== null || virtual_id !== null ? '?page=browser' : '';
        search += category_id !== null ? `&&category-id=${category_id}` : '';
        search += virtual_id !== null ? `&&virtual-id=${virtual_id}` : '';
        search += product_id !== null ? `&&product-id=${product_id}` : '';
    ;
    console.log(search);
        click({ pathname: '/', search: search},
            product_id !== null
                ? null
                : category_id !== null && virtual_id !== null
                    ? triggerProductBoth
                    : category_id !== null || virtual_id !== null
                        ? triggerProductList
                        : triggerProductReco
        );
    }

    return (
        <div className={`information${!min ? ' information--min' : ''}`}>
            <ButtonBlack onClick={() => click({ pathname: '/', search: '' }, triggerProductReco)} className={`information__sort${!min ? ' information__sort--min' : ''}`} value='Vissza a Főoldalra'/>
            <ButtonBlack onClick={back} className={`information__dir${!min ? ' information__dir--min' : ''}`} value='Vissza'/>
            <div className='information__container'>
                {info.map((inf, i) => (
                    <label key={i} style={inf.style}>{inf.text}</label>
                ))}
            </div>            
        </div>
    );
}
const mapDispatchToProps = dispatch => ({
    locationChanged: () => dispatch(locationChanged())
});
export default withRouter(connect(null, mapDispatchToProps)(Information));