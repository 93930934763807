import React, { useState, useEffect } from 'react';

const TextBoxAuto = ({ triggeredOne, triggeredTwo, id, setTextboxId, datas, classNameTextbox, classNameContainer, classNameData, onChangeFunc, isCategory, text, name }) => {
    const [currentDatas, setCurrentDatas] = useState([]);
    const [onDisplayText, setOnDisplayText] = useState('none');     
    const [idx, setIdx] = useState(-1);  

    let refInput = React.createRef();
    let refList = React.createRef();  
    
    let movedOut = true;

    useEffect(() => {
        const handleResize = () => {  
            if (isCategory === true) {
                refList.current.style.left = `${refInput.current.offsetLeft + 10}px`;
                refList.current.style.width = `${refInput.current.offsetWidth - 20}px`;
                refList.current.style.top = `${refInput.current.offsetTop + 22}px`;                     
            }
            else {
                refList.current.style.left = `${refInput.current.offsetLeft}px`;
                refList.current.style.width = `${refInput.current.offsetWidth}px`;                         
                refList.current.style.top = `${refInput.current.offsetTop
                    - (triggeredOne !== undefined ? document.getElementsByClassName(triggeredOne)[0].scrollTop : 0)
                    - (triggeredTwo !== undefined ? document.getElementsByClassName(triggeredTwo)[0].scrollTop : 0)
                    + 18.2}px`;  
            }                                  
        }

        window.addEventListener('resize', handleResize);

        handleResize(); 

        return _=> {
            window.removeEventListener('resize', handleResize);
        }
    })

    const CheckForMatch = e => {
        onChangeFunc(id, name, e.target.value);            
        if (datas !== undefined) {
            let foundMatches = [];
            for (let i = 0; i < datas.length; i++) {
                if (e.target.value !== '' && datas[i].toUpperCase().includes(e.target.value.toUpperCase())) foundMatches.push(datas[i]);
            }        
            setCurrentDatas(foundMatches);
            if (foundMatches.length === 0) setOnDisplayText('none');
            else setOnDisplayText('block');            
        }
    }

    const CheckForArrows = e => {        
        if (datas !== undefined) {
            switch(e.keyCode) {
                case 38: {
                    if (idx === -1 || idx === 0) setIdx(currentDatas.length - 1);
                    else setIdx(idx - 1);
                    break;
                }
    
                case 40: {
                    if (idx === -1 || idx === currentDatas.length - 1) setIdx(0);
                    else setIdx(idx + 1);
                    break;
                }
    
                case 13: {                                                           
                    if (onDisplayText !== 'none') {
                        onChangeFunc(id, name, currentDatas[idx]);
                        setOnDisplayText('none');
                        setIdx(-1);
                    }
                    break;
                }
    
                default: break;
            }
        }
    }

    const onSelect = e => {                   
        onChangeFunc(id, name, e.target.innerText);
        setOnDisplayText('none');
        setIdx(-1);        
    }

    const lostFocus = e => {  
        //console.log('moved: ' + movedOut + ' display: ' + onDisplayText);            
        if (movedOut) setOnDisplayText('none');        
    }        

    const mouseIn = e => {
        movedOut = false;
    }

    const mouseOut = e => {        
        movedOut = true;        
    }

    /*const focus = e => {
        setTextboxId(id);
    }*/

    return (
        <>
        <input ref={refInput} value={text} className={`textbox-auto__text${classNameTextbox !== undefined ? ` ${classNameTextbox}` : ''}`} type='text' onChange={CheckForMatch} onBlur={lostFocus} onKeyDown={CheckForArrows}/>
        <div onMouseEnter={mouseIn} onMouseLeave={mouseOut} ref={refList} className={`textbox-auto__container${classNameContainer !== undefined ? ` ${classNameContainer}` : ''}`} style={{display : onDisplayText }} >
            {currentDatas.map((data, i) => (
                <label onClick={onSelect} className={`textbox-auto__container__data${i === idx ? ` textbox-auto__container__data--selected` : ''}${classNameData !== undefined ? ` ${classNameData}` : ''}`} key={i} >{data}</label>
            ))}
        </div>
        </>
    )
}
/*const mapDispatchToProps = dispatch => ({
    setTextboxId: i => dispatch(setTextboxId(i))
})*/
export default /*connect(null, mapDispatchToProps)(*/TextBoxAuto;