import React from 'react';

import ItemCategoryMain from './item-category-main';
import ButtonCircle from './button-circle';
import { delay } from '../utils';

const DirectorySort = ({ tag, triggerProductBoth, triggerProductList, categories, float, min, category_id }) => {       
    
    const click = async () => {
        if (!min) {
            let sorts = document.getElementsByClassName('dir-sort');
            for (let s = 0; s < sorts.length; s++) {
                if (sorts[s].className.includes('--active')) {                        
                    for (let i = 100; i >= 0; i-=10) {
                        sorts[s].style.clipPath = `polygon(0 0, 100% 0, 100% ${i}%, 0 ${i}%)`;
                        await delay(10);
                    }
                    sorts[s].style.clipPath = 'none';
                    sorts[s].className = 'dir-sort dir-sort--left dir-sort--min';
                    document.getElementsByClassName('dir-sort__close')[s].className = 'button-circle dir-sort__close';               
                }
            }            
        }
    }

    return (
        <div className={`dir-sort${float === 'left' ? ' dir-sort--left' : float === 'right' ? ' dir-sort--right' : ''}${!min ? ' dir-sort--min' : ''}`}>
            <ButtonCircle className='dir-sort__close' value='X' onClick={click}/>
            <div className='dir-sort__category-container'>
                {categories.map((categ, i) => (
                    <ItemCategoryMain tag={tag} min={min} triggerProductBoth={triggerProductBoth} triggerProductList={triggerProductList} key={i} category={categ} float={float} category_id={category_id}/>
                ))}
            </div>            
        </div>        
    );
}
export default DirectorySort;