import React, { useEffect } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { clearOpened } from './redux/product/product.actions';

import Routing from './routing';

import './sass/main.scss';

function App({ clearOpened }) {    

  useEffect(() => {
    clearOpened();    
  },[clearOpened]);

  return (
    <div className="App">
      <BrowserRouter>
        <Switch>
          <Route path='/' render={() => (<Routing />)}/>              
        </Switch>
      </BrowserRouter>      
    </div>
  );
}
const mapDispatchToProps = dispatch => ({
  clearOpened: () => dispatch(clearOpened())
});
export default connect(null, mapDispatchToProps)(App);
