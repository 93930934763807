import React, { useEffect, useCallback, useState } from 'react';

import Navbar from '../components/navbar';
import CategorySideLib from '../components/category-side-lib';
import ProductLib from '../components/product-lib';
import ProductModBoard from '../components/product-mod-board';
import CategoryVirtualSideLib from '../components/category-virtual-side-lib';
import PanelInfo from '../components/pnl-info';
import PopUpForm from '../components/pop-up-form';

import { connect } from 'react-redux';
import { setProg } from '../redux/product/product.actions';

import { splitNumber } from '../utils';

import axios, { resources } from '../axios';

import { v4 as uuidv4 } from 'uuid';

const Dashboard = ({ prog, openedMain, openedSub, setProg }) => {      

    const STATE_INIT = {
        popUpInput: { static: '', item: '', onYes: null, onNo: null, display: false },
        isActive: false,
        activeProduct: null,
        //handleYes: null,

        virtualCategories: [],
        images: [],
        images_db: [],

        products: [],
        categories: {
            main: '',
            sub: ''
        },
        isSketch: true,

        lookImg: null,

        virtualLib: false,

        panelInfo: false,

        sideSwitch: 'img',

        update: false
    };
    
    const [state, setState] = useState(STATE_INIT);

    useEffect(() => {
        console.log('effect');
        if (window.innerWidth <= 900) {
            if (state.sideSwitch === 'product' && state.activeProduct === null) {
                document.getElementsByClassName('category-side-lib')[0].style.width = '0';
                document.getElementsByClassName('product-lib')[0].style.width = '100vw';
                document.getElementsByClassName('product-lib__add')[0].style.display = 'block';
                document.getElementsByClassName('product-lib__other')[0].style.display = 'block';
            }
            else if (prog === 'img' && state.sideSwitch === 'img' && state.activeProduct !== null) {
                let sw = document.getElementsByClassName('product-mod-board__switch')[0];
                document.getElementsByClassName('product-mod-board__toProducts')[0].style.display = 'none';
                sw.style.right = '11rem';
                sw.style.top = '3rem';
            }
        }
        /*if (state.handleYes !== null && state.activeProduct === null){
            state.handleYes();
            console.log('yes');
            setState(s => ({...s, handleYes: null}));
        }*/
    })
    
    const doUpdate = () => {
        setState({...state, update: !state.update});
    }
    
    const setStateOwn = useCallback(
        (s) => {
            if (state.update || !state.update) setState(s);
        },
        [state.update],
    )

    const setImagesDb = useCallback(
        (imgs) => {
            if (state.update || !state.update) setState(s => ({...s, images_db: imgs}));
        },
        [state.update],
    )

    const getImages = useCallback(
        (category = null, openSub = null) => {
            const data = new FormData();
            data.append('main_id', openedMain.id);            
            if (category !== null) data.append('sub_id', category.id);
            else data.append('sub_id', openedSub.id);
            axios.post('get-images.php', data)
            .then(res => {
                console.log(res);
                if (category !== null) openSub(category);
                setImagesDb(res.data.result);            
            });
        },
        [setImagesDb, openedMain, openedSub],
    );

    const popUpInputProperties = popUpInput => {
        setState({...state, popUpInput: popUpInput});
    }

    const setPopUpValue = (name, value) => {
        setState({...state, popUpInput: {...state.popUpInput, [name]: value}});
    }

    const setCategories = array => {
        setState({...state, virtualCategories: array});
    }    

    const addImage = idx => {        
        if (state.images.length < 4) {
            setState({...state, images: [...state.images, state.images_db[idx]], images_db:
                state.images_db.map((img, i) => i === idx
                ? {...img, selected: true}
                : img)});            
        }       
    }

    const deleteImage = name => {
        let i = 0;
        while (i < state.images_db.length && state.images_db[i].name !== name) i++;
        if (i === state.images_db.length) {
            const data = new FormData();
            data.append('name', name);
            data.append('main_id', openedMain.id);
            data.append('sub_id', openedSub.id);
            data.append('id', uuidv4());
            axios.post('add-image-conn.php', data)
            .then(res => {
                console.log(res);
                setState({...state, images: state.images.filter(img => img.name !== name ), images_db: [...state.images_db, {name: name, selected: false}]});                 
            })
        }   
        else{
            setState({...state, images: state.images.filter(img => img.name !== name ), images_db:
                state.images_db.map(img => img.name === name
                ? {...img, selected: false}
                : img)});    
        }                    
    }

    const switchImage = (i, j) => {        
        setState({...state, images: state.images.map((img, idx) => idx === i || idx === j
        ? idx === i ? state.images[j] : state.images[i]
        : img)});
    }

    const clickBackToProducts = (isTrue = false) => {
        popUpInputProperties({ 
            static: 'Tuti?',
            item: null,
            onNo: () => setPopUpValue('display', false),
            onYes: () => handleBackToProducts(isTrue),
            display: true
        });                
    }

    const handleBackToProducts = isTrue => { 
        console.log('set');      
        
        if (isTrue) {
            setState({...state, popUpInput: {...state.popUpInput, display: false}, isActive: false, activeProduct: null, images: [], isSketch: true, products: [], images_db: []});
            setProg('sub');
        }                
        else setState({...state, popUpInput: {...state.popUpInput, display: false}, isActive: false, activeProduct: null, images: [], isSketch: true});                
    }        

    return (
        <div className='dashboard'>   
        {console.log(state)}                    
            <CategorySideLib doUpdate={doUpdate} setStateOwn={setStateOwn} images={state.images_db} getImages={getImages} setImages={setImagesDb} clickBackToProducts={clickBackToProducts} isActive={state.isActive} addImage={addImage} popUpInputProperties={popUpInputProperties} setPopUpValue={setPopUpValue} /> 
            {prog === 'img' 
            ? state.isActive === true ? <ProductModBoard sideSwitch={state.sideSwitch} doUpdate={doUpdate} getImages={getImages} setStateOwn={setStateOwn} isSketch={state.isSketch} virtualCategories={state.virtualCategories} setCategories={setCategories} deleteImage={deleteImage} clickBackToProducts={clickBackToProducts} switchImage={switchImage} images={state.images} product={state.activeProduct}/> : <ProductLib sideSwitch={state.sideSwitch} doUpdate={doUpdate} setStateOwn={setStateOwn} products={state.products} categories={state.categories} isSketch={state.isSketch} splitNumber={splitNumber}/>
            : null}            
            <Navbar doUpdate={doUpdate} setStateOwn={setStateOwn}/>               
            <div className='look-on' style={{ display: state.lookImg !== null ? 'block' : 'none' }} onClick={() => setState({...state, lookImg: null})}>
                <div className='look-on__box'>
                    <img className='look-on__box__image' alt='look on view' src={state.lookImg !== null ? resources + state.lookImg : ''}/>
                </div>
            </div>      
            {state.virtualLib ? <CategoryVirtualSideLib doUpdate={doUpdate} setStateOwn={setStateOwn}/> : null}           
            {state.panelInfo ? <PanelInfo doUpdate={doUpdate} setStateOwn={setStateOwn}/> : null}
            <PopUpForm popUpInput={state.popUpInput} />       
        </div>
    );
}
const mapStateToProps = state => ({
    prog: state.product.prog,
    openedMain: state.product.openedMain,
    openedSub: state.product.openedSub
});
const mapDispatchToProps = dispatch => ({   
    setProg: v => dispatch(setProg(v))   
});
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);