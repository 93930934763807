import React from 'react';

import Login from '../components/login';

const LoginPage = () => {

    return (
        <div className='login-page'>
            <Login />
        </div>
    )
}
export default LoginPage;