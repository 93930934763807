import React from 'react';

const LoadingSymbol = (props) => {

    return (
        <div className='loading-symbol' style={props.style}>
            {window.innerWidth > 900 ?
            <div className='loading-symbol__box'>               
                <div className='loading-symbol__box__dot loading-symbol__box__dot--anim1'></div>
                <div className='loading-symbol__box__dot loading-symbol__box__dot--anim2'></div> 
                <div className='loading-symbol__box__dot loading-symbol__box__dot--anim3'></div>                                              
            </div>
            : <div className='loading-symbol__box loading-symbol__box--lonely'/>}
        </div>
    );
}
export default LoadingSymbol;