import React from "react";

import ItemCategorySub from "./item-category-sub";

const ItemCategoryMain = ({ tag, min, triggerProductBoth, triggerProductList, category, float, category_id }) => (
    <div className={`item-category-main
        ${float === 'left' ? ' item-category-main--left' : float === 'right' ? ' item-category-main--right' : ''}
        ${!min ? ' item-category-main--min' : ''}
    `}>
        <div className='item-category-main__box'>
            <span className={`item-category-main__box__name
                ${float === 'left' ? ' item-category-main__box__name--left' : float === 'right' ? ' item-category-main__box__name--right' : ''}
                ${!min ? ' item-category-main__box__name--min' : ''}
            `}>{category.name}</span>
        </div>
        <div className={`item-category-main__sub-container
            ${float === 'left' ? ' item-category-main__sub-container--left' : float === 'right' ? ' item-category-main__sub-container--right' : ''}            
        `}>
            {category.array.map((sub, i) => (
               <ItemCategorySub tag={tag} min={min} triggerProductBoth={triggerProductBoth} triggerProductList={triggerProductList} key={i} category={sub} float={float} category_id={category_id}/>
            ))}
        </div>
    </div>
)
export default ItemCategoryMain;