import React, { useEffect } from "react";
import { splitNumber, encrypt } from "../utils";
import { connect } from "react-redux";
import { locationChanged } from "../redux/product/product.actions";
import { withRouter } from "react-router-dom";

import ProductPreviewCustomer from "./product-preview-customer";
import ItemCategoryVirtual from "./item-category-virtual";
import ButtonBlack from "./button-black";

const Product = ({ med, min, triggerProductBoth, triggerProductList, triggerProductReco, category_id, virtual_id, product, images, virtCategs, locationChanged, history }) => {
   
    const refText = React.createRef();

    useEffect(() => {
       if (refText.current !== null) refText.current.style.height = `${refText.current.scrollHeight + 3}px`;
    })

    const click = (p, s, t) => {
        //history.replace({ pathname: p, search: s });
        history.push(s);
        locationChanged();  
        if (t !== null) t(); 
    }

    const categClick = id => {
        click('/', `?page=browser&&virtual-id=${encrypt(id)}`, triggerProductList);
    }

    return (
        <div className={`product${med ? ' product--med' : !min ? ' product--min' : ''}`}>   
        {console.log(category_id + ' ' + virtual_id)}  
            <ButtonBlack onClick={() => click('/', '', triggerProductReco)} className={`product__sort${!min ? ' product__sort--min' : ''}`} value='Vissza a Főoldalra'/>
            
            {/*<ButtonBlack onClick={() => 
                category_id !== null || virtual_id !== null
                    ? category_id !== null && virtual_id !== null
                        ? click('/', `?page=browser&&category-id=${category_id}&&virtual-id=${virtual_id}`, triggerProductBoth)
                        : category_id !== null
                            ? click('/', `?page=browser&&category-id=${category_id}`, triggerProductList)
                            : virtual_id !== null
                                ? click('/', `?page=browser&&virtual-id=${virtual_id}`, triggerProductList)
                                : click('/', '', triggerProductReco)
                    : click('/', '', triggerProductReco)
            } className={`product__dir${!min ? ' product__dir--min' : ''}`} value='Vissza'/>*/}

            <ButtonBlack onClick={() => history.go(-1)}className={`product__dir${!min ? ' product__dir--min' : ''}`} value='Vissza'/>
            <ButtonBlack className={`product__inf${!min ? ' product__inf--min' : ''}`} value='Információk' onClick={() =>                 
                category_id !== null || virtual_id !== null
                ? category_id !== null && virtual_id !== null
                    ? click('/', `?page=info&&category-id=${category_id}&&virtual-id=${virtual_id}&&product-id=${encrypt(product.id)}`, null)
                    : category_id !== null
                        ? click('/', `?page=info&&category-id=${category_id}&&product-id=${encrypt(product.id)}`, null)
                        : virtual_id !== null
                            ? click('/', `?page=info&&virtual-id=${virtual_id}&&product-id=${encrypt(product.id)}`, null)
                            : click('/', `?page=info&&product-id=${encrypt(product.id)}`, null)
                : click('/', `?page=info&&product-id=${encrypt(product.id)}`, null)
            }/>     
           {product !== null ?
           <>
                <ProductPreviewCustomer med={med} min={min} images={images}/>
                <span className='product__name'>{product.name}</span>
                <span className='product__price'>{`${splitNumber(product.price)} Ft`}</span>
                <div className={`product__categories${med ? '' : !min ? ' product__categories--min' : ''}`}>
                    {min ?
                    <>
                    <div className='product__categories__col-1'>
                    {virtCategs.map((categ, i) => i < 5 ? (
                        <ItemCategoryVirtual key={i} virtual={categ} onClick={() => categClick(categ.id)}/>
                    )
                    : null)}
                    </div>
                    <div className='product__categories__col-2'>
                    {virtCategs.map((categ, i) => i >= 5 && i < 10 ? (
                        <ItemCategoryVirtual key={i} virtual={categ} onClick={() => categClick(categ.id)}/>
                    )
                    : null)}
                    </div>
                    <div className='product__categories__col-3'>
                    {virtCategs.map((categ, i) => i >= 10 && i < 15 ? (
                        <ItemCategoryVirtual key={i} virtual={categ} onClick={() => categClick(categ.id)}/>
                    )
                    : null)}
                    </div>
                    </>
                    :
                    <div className='product__categories__col-1 product__categories__col-1--min'>
                    {virtCategs.map((categ, i) => i < 15 ? (
                        <ItemCategoryVirtual key={i} virtual={categ} onClick={() => categClick(categ.id)}/>
                    )
                    : null)}
                    </div>}                    
                </div>
                <textarea ref={refText} readOnly className='product__description' value={product.description}/>          
           </>
           :null}
        </div>
    );
}
const mapDispatchToProps = dispatch => ({
    locationChanged: () => dispatch(locationChanged())
});
export default withRouter(connect(null, mapDispatchToProps)(Product));