import React, { useState, useEffect } from 'react';

import ProductPreview from './product-preview';
import ButtonCircle from './button-circle';
import TextBoxAuto from './textbox-autocomplete';

import { v4 as uuidv4 } from 'uuid';

import axios from '../axios';

import { connect } from 'react-redux';

const ProductModBoard = ({ sideSwitch, getImages, doUpdate, setStateOwn, isSketch, clickBackToProducts, deleteImage, switchImage, setCategories, virtualCategories, images, product, openedMain, openedSub }) => {

    const categInit = { categ_names: [], categ_values: [] };

    const [datas, setDatas] = useState(categInit);      
    
    const getVirtualCategories = () => {
        axios.get('get-virtual-categories.php')
        .then(res => {
            console.log(res);
            setDatas(res.data);
        });
    }    
    
    useEffect(() => {
        getVirtualCategories();
    }, [isSketch]);

    const change = e => {       
        setStateOwn(s => ({...s, activeProduct: {...s.activeProduct, [e.target.name]: e.target.value }}));
    }    

    const changeCategory = (id, name, value) => {        
        setCategories(virtualCategories.map(category => category.id === id
            ? {...category, [name]: value}
            : category));
    }    

    const saveProduct = e => {
        document.getElementsByClassName('product-mod-board__save-box__save-indi')[0].style.display = 'block';
        const data = new FormData();
        data.append('product', JSON.stringify(product));       
        data.append('virtualCategories', JSON.stringify(virtualCategories));
        if (images.length !== 0) data.append('images', JSON.stringify(images));
        else data.append('images', JSON.stringify({name: 'https://via.placeholder.com/150'}));
        let i = 0;
        while (i < virtualCategories.length + images.length) {
            data.append('ids[]', uuidv4());
            i++;
        }
        axios.post('save-product.php', data)
        .then(res => {
            console.log(res);
            doUpdate();
            setStateOwn(s => ({...s, activeProduct: { id: uuidv4(), name: '', main_id: openedMain.id, sub_id: openedSub.id, price: 0, description: '' }, virtualCategories: [], images: []}));            
            getImages();
            getVirtualCategories();
            document.getElementsByClassName('product-mod-board__save-box__save-indi')[0].style.display = 'none';
        });
    }   
    
    const switchWindow = () => {
        let sw = document.getElementsByClassName('product-mod-board__switch')[0];
        if (sideSwitch === 'product') {
            document.getElementsByClassName('category-side-lib')[0].style.width = '100vw';
            document.getElementsByClassName('product-mod-board')[0].style.width = '0';
            document.getElementsByClassName('product-mod-board__toProducts')[0].style.display = 'none'; 
            document.getElementsByClassName('category-side-lib__info-header__set')[0].style.display = 'block';
            sw.style.right = '11rem';
            sw.style.top = '3rem';
            setStateOwn(s => ({...s, sideSwitch: 'img'}));
        }
        else if (sideSwitch === 'img') {
            document.getElementsByClassName('category-side-lib')[0].style.width = '0';
            document.getElementsByClassName('product-mod-board')[0].style.width = '100vw';
            document.getElementsByClassName('product-mod-board__toProducts')[0].style.display = 'block';
            document.getElementsByClassName('category-side-lib__info-header__set')[0].style.display = 'none';     
            sw.style.right = '1rem';
            sw.style.top = '13rem';
            setStateOwn(s => ({...s, sideSwitch: 'product'}));
        }
    }

    const moveBack = () => {
        clickBackToProducts()               
    }

    return (
        <div className='product-mod-board'>
            <ProductPreview deleteImage={deleteImage} switchImage={switchImage} images={images} />  
            <ButtonCircle className='product-mod-board__toProducts' value='V' onClick={moveBack}/>   
            <ButtonCircle className='product-mod-board__switch' onClick={switchWindow} value={sideSwitch === 'product' ? <>&larr;</> : <>&rarr;</>}/>
            <div className='product-mod-board__holder-top'>
                <input name='name' type='text' className='product-mod-board__holder-top__name' value={product.name} placeholder='Terméknév' onChange={change}/>
                <label className='product-mod-board__holder-top__currency'>Ft</label>
                <input name='price' type='number' className='product-mod-board__holder-top__price' value={product.price} onChange={change}/>                
            </div>
            <div className='product-mod-board__category-container'>
            {virtualCategories.map((category, i) => (
                <div key={i} className='product-mod-board__category-list'>
                    <ButtonCircle className='product-mod-board__category-list__del' value={<>&#128465;</>} onClick={() => setCategories(virtualCategories.filter((c, idx) => idx !== i))}/>
                    <div className='product-mod-board__category-list__holder'>
                        <TextBoxAuto triggeredOne='product-mod-board' triggeredTwo='product-mod-board__category-container' name='name' onChangeFunc={changeCategory} datas={datas.categ_names} id={category.id} classNameTextbox='product-mod-board__category-list__holder__name' text={category.name} isCategory={false}/>
                        <label className='product-mod-board__category-list__holder__colon'>&#58;</label>
                        <TextBoxAuto triggeredOne='product-mod-board' triggeredTwo='product-mod-board__category-container' name='value' onChangeFunc={changeCategory} datas={datas.categ_values} id={category.id} classNameTextbox='product-mod-board__category-list__holder__value' text={category.value} isCategory={false}/>
                    </div>                    
                </div>
            ))}      
            <ButtonCircle style={{ display: virtualCategories.length < 15 ? 'inline-block' : 'none' }} className='product-mod-board__category-list__add' value='+' onClick={() => setCategories([...virtualCategories, { id: uuidv4(), name: '', value: '' }])}/>      
            </div>
            <div className='product-mod-board__holder-bot'>
                <textarea name='description' className='product-mod-board__holder-bot__description' value={product.description} placeholder='Leírás' onChange={change}/>
            </div>
            <div className='product-mod-board__save-box'>
                <ButtonCircle className='product-mod-board__save-box__save-product' value='Mentés' onClick={saveProduct}/>
                <div className='product-mod-board__save-box__save-indi'/>
            </div>            
        </div>
    );
}
const mapStateToProps = state => ({
    openedMain: state.product.openedMain,
    openedSub: state.product.openedSub
});
export default connect(mapStateToProps)(ProductModBoard);