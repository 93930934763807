import React from 'react';

const LoadingSign = () => {

    return (
        <div className='loading-sign'>
            <div className='loading-sign__box'>
                <div className='loading-sign__box__sign'>Szíves türelmét kérjük...</div>
            </div>
        </div>
    );
}
export default LoadingSign;