import React from "react";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { locationChanged } from "../redux/product/product.actions";

import ButtonBlack from "./button-black";
import ItemProduct from "./item-product";

const DirectoryProduct = ({titles, triggerProduct, triggerProductReco, products, category_id, virtual_id, history, locationChanged }) => {        
    
    const click = e => {
        //history.replace({ pathname: '/', search: '' });      
        history.push('/');  
        locationChanged();   
        triggerProductReco(); 
    }    

    return (
        <div className='dir-product'>   
        {console.log(products)}
            <div className='dir-product__header'>
                {category_id === null && virtual_id === null
                ? <span className='dir-product__header__title dir-product__header__title-main'>Ajánló</span>
                : <>
                    <ButtonBlack onClick={click} className='dir-product__header__sort' value='Vissza a Főoldalra'/>
                    <div className='dir-product__header__title-container'>
                        {titles.map((title, i) => (
                            <span key={i} className='dir-product__header__title-container__title'>{`${title.main}: ${title.sub}`}</span>
                        ))}
                    </div>                    
                </>}
            </div>     
            {products !== undefined && products.both !== undefined && products.virtual !== undefined
            ? <div>               
                {products.both.length !== 0             
                ? <div className={`dir-product__container${category_id !== null || virtual_id !== null ? ' dir-product__container--margin-big' : ''}`}>
                    {products.both.map((product, i) => (
                        <ItemProduct triggerProduct={triggerProduct} category_id={category_id} virtual_id={virtual_id} key={i} product={product}/>
                    ))}
                </div>
                : null}
                {products.both.length !== 0 && products.virtual.length !== 0
                ? <div className='dir-product__sep'/>
                : null}
                {products.virtual.length !== 0             
                ? <div className={`dir-product__container${category_id !== null || virtual_id !== null ? ' dir-product__container--margin-big' : ''}`}>
                    {products.virtual.map((product, i) => (
                        <ItemProduct triggerProduct={triggerProduct} category_id={category_id} virtual_id={virtual_id} key={i} product={product}/>
                    ))}
                </div>
                : null}
            </div>
            : <div className={`dir-product__container${category_id !== null || virtual_id !== null ? ' dir-product__container--margin-big' : ''}`}>
            {products !== undefined && products.map((product, i) => (
                <ItemProduct triggerProduct={triggerProduct} category_id={category_id} virtual_id={virtual_id} key={i} product={product}/>
            ))}
        </div>}                                                    
        </div>
    );
}
const mapDispatchToProps = dispatch => ({
    locationChanged: () => dispatch(locationChanged())
});
export default withRouter(connect(null, mapDispatchToProps)(DirectoryProduct));