import React, { useEffect, useState, useCallback } from 'react';

import { connect } from 'react-redux';
import { openMain, openSub, setProg } from '../redux/product/product.actions';

import axios from '../axios';

import { v4 as uuidv4 } from 'uuid';

import ImageSideLib from './image-side-lib';
import ButtonCircle from './button-circle';
import TextBoxAuto from './textbox-autocomplete';
import LoadingSymbol from './loading-symbol';

const CategorySideLib = ({ doUpdate, setStateOwn, getImages, images, setImages, clickBackToProducts, isActive, addImage, popUpInputProperties, setPopUpValue, prog, openedMain, openedSub, openMain, openSub, setProg }) => {

    const INITIAL_STATE = {
        categories: [],
        categories_tho: [],
        names: [],
        inChanging: false,
        load: false
    };

    const [state, setState] = useState(INITIAL_STATE);                

    const deleteImage = name => {
        const data = new FormData();
        console.log(openedMain.id);
        console.log(openedSub.id);
        data.append('name', name);
        data.append('main_id', openedMain.id);
        data.append('sub_id', openedSub.id);
        axios.post('delete-image.php', data)
        .then(res => {
            console.log(res);
            getImages();
        })
    }

    const getCategoriesMain = () => {                                    
        axios.get('get-categories-main.php')        
        .then(res => {            
            console.log(res);
            if (res.data.result !== false) {                                        
                setState(s => ({...s, categories: res.data.result.map(categ => ({...categ, del: 0, add: 0})), categories_tho: res.data.result}));                                                       
            }                                      
        });                
    }

    const getCategoriesSub = useCallback(
        (category = null) => {        
            const data = new FormData();           
            if (category !== null) data.append('id', category.id);
            else data.append('id', openedMain.id);        
            axios.post('get-categories-sub.php', data)
            .then(res => {
                console.log(res);
                if (category !== null) openMain(category);                
                setState(s => ({...s, names: res.data.categ_names, categories: res.data.result.map(categ => ({...categ, del: 0, add: 0})), categories_tho: res.data.result}));                                                       
            });
        },
        [openMain, openedMain.id],
    );    
   
    const deleteClick = e => {          
        const idx = Number(e.target.parentNode.dataset.index);
        popUpInputProperties({ 
            static: 'Biztosan ki szeretné törölni ezt az elemet?',
            item: `${prog === 'main' ? 'Főkategória:' : 'Alkategória:'} ${state.categories_tho[idx].name}`,
            onNo: () => { setPopUpValue('display', false); },
            onYes: () => {                        
                const id = state.categories_tho[idx].id;                               
                setState(s => ({...s, categories: s.categories.map(categ => categ.id === id ? {...categ, del: 1} : categ), categories_tho: s.categories_tho.filter(categ => categ.id !== id)}));                                                                                  
                setPopUpValue('display', false);  
            },
            display: true
        });     
    }   
    useEffect(() => {       
        if (prog === 'main') getCategoriesMain();
        else if (prog === 'sub') getCategoriesSub();        
        else if (prog === 'img') getImages(null, openSub);              
    },[prog, getCategoriesSub, getImages, openedMain, openedSub, openSub]);  

    const saveChanges = () => {              
        const data = new FormData();
        if (prog === 'main') {
            data.append('is_main', 1);
            data.append('main_id', 'none');
        }
        else {
            data.append('is_main', 0);
            data.append('main_id', openedMain.id);
        }
        data.append('categories', JSON.stringify(state.categories));
        axios.post('modify-categories.php', data)
        .then(res => {
            console.log(res);
            if (prog === 'main') getCategoriesMain();
            else getCategoriesSub();
        })
    }

    const moveUp = (id, idx) => {
        console.log(state.categories[idx].name + ' ' + id);
        console.log(state.categories_tho[idx].id + ' ' + idx);
        console.log(state.categories[idx - 1].id + ' ' + id);
        console.log(state.categories_tho[idx - 1].id + ' ' + idx);
        setState(s => ({...s, categories: s.categories.map((categ, i) => i === idx - 1 || id === categ.id
        ? i === idx - 1 ? {...s.categories[idx], queue_number: s.categories[idx - 1].queue_number} : {...s.categories[idx - 1], queue_number: s.categories[idx].queue_number}
        : categ), categories_tho: s.categories_tho.map((categ, i) => i === idx - 1 || id === categ.id
        ? i === idx - 1 ? s.categories_tho[idx] : s.categories_tho[idx - 1]
        : categ)}));
    }

    const moveDown = (id, idx) => {
        console.log(state.categories[idx].name + ' ' + id);
        console.log(state.categories_tho[idx].id + ' ' + idx);
        console.log(state.categories[idx + 1].id + ' ' + id);
        console.log(state.categories_tho[idx + 1].id + ' ' + idx);
        setState(s => ({...s, categories: s.categories.map((categ, i) => i === idx + 1 || id === categ.id
            ? i === idx + 1 ? {...s.categories[idx], queue_number: s.categories[idx + 1].queue_number} : {...s.categories[idx + 1], queue_number: s.categories[idx].queue_number}
            : categ), categories_tho: s.categories_tho.map((categ, i) => i === idx + 1 || id === categ.id
            ? i === idx + 1 ? s.categories_tho[idx] : s.categories_tho[idx + 1]
            : categ)}));
    }

    const clickSet = e => {
        if (state.inChanging === true) saveChanges();
        setState(s => ({...s, inChanging: !s.inChanging}));       
    }

    const changed = e => { 
        let _target = e.target;
        while (_target.dataset.id === undefined) _target = _target.parentNode;             
        setState(s => ({...s, categories: s.categories.map(category => category.id === _target.dataset.id
            ? {...category, name: e.target.value}
            : category), categories_tho: s.categories_tho.map(category => category.id === _target.dataset.id
            ? {...category, name: e.target.value}
            : category)}));
    }

    const changeCategory = (id, _, value) => {                 
        setState(s => ({...s, categories: s.categories.map(category => category.id === id
            ? {...category, name: value}
            : category), categories_tho: s.categories_tho.map(category => category.id === id
            ? {...category, name: value}
            : category)}));
    }

    const addCategory = e => {
        const id = uuidv4();
        setState(s => ({...s, categories: [...s.categories, { id: id, name: '', is_main: prog === 'main' ? 1 : 0, add: 1, del: 0, queue_number: state.categories_tho.length}],
        categories_tho: [...s.categories, { id: id, name: '', is_main: prog === 'main' ? 1 : 0}]}));       
    }
    

    const openCategory = e => {                 
        const idx = Number(e.target.parentNode.dataset.index);
        const category = state.categories[idx];
        setState(s => ({...s, categories_tho: [], categories: []}));        
        if (prog === 'main') {                                
            getCategoriesSub(category);
        }      
        else if (prog === 'sub') {            
            getImages(category, openSub);            
        }        
    }

    const goBack = e => {
        setState(s => ({...s, categories_tho: [], categories: []}));    
        switch (prog) {
            case 'sub':
                setProg('main');
                break;
            
            case 'img':
                if (isActive) clickBackToProducts(true);
                else {
                    doUpdate();
                    setStateOwn(s => ({...s, products: []}));
                    setImages([]);
                    setProg('sub');
                }
                break;

            default: break;
        }        
    }   

    const triggerFile = e => {
        document.getElementsByClassName('category-side-lib__info-header__file')[0].click();
    }

    const FileOnchange = e => {
        handleFiles(e.target.files);
    }

    const handleFiles = loadedFiles => {
        let lock = false;
        for (var i = 0; i < loadedFiles.length; i++) {                   
            if (images.find(img => img.name.toUpperCase() === loadedFiles[i].name.toUpperCase()) !== undefined) {
                lock = true;
                break;
            }
        }   
        if (!lock) {
            if (loadedFiles.length !== 0) {
                setState(s => ({...s, load: true}));            
            }                 
            const formData = new FormData();
            formData.append('main_id', openedMain.id);
            formData.append('sub_id', openedSub.id);
            const fileNamesTemp = [];                 
            for (var i = 0; i < loadedFiles.length; i++){            
                if (loadedFiles[i].type === 'image/jpeg'){                                  
                    formData.append('files[]', loadedFiles[i]);
                    formData.append('ids[]', uuidv4());
                                        
                    let fileName = loadedFiles[i].name === 'image.jpg' ? `${uuidv4()}.jpg` : loadedFiles[i].name;
    
                    console.log(fileName + ' ' + fileName.length);
    
                    if (fileName.length >= 36) {
                        fileName = fileName.substr(0,30) + '.jpg';
                    }                                
    
                    console.log('loaded ' + fileName);
                                                                 
                    formData.append('names[]', fileName);                   
                    fileNamesTemp.push(fileName);                                                                               

                    if (fileNamesTemp.length === loadedFiles.length) {
                        console.log('fetch - ' + fileNamesTemp.length);
                        axios.post('save-images.php', formData)
                        .then(res => {
                            console.log(res);   
                            setState(s => ({...s, load: false}));   
                            doUpdate();
                            setImages(res.data.images.result);
                        })                        
                    }            
                }                        
            }
        }                                  
    }
    
    const viewImg = idx => {
        setStateOwn(s => ({...s, lookImg: images[idx].name}));
    }    

    return (
        <div className='category-side-lib'>     
        {console.log(state)}          
            <div className={`category-side-lib__info-header${prog === 'main' ? ' category-side-lib__info-header--min' : ''}`}>
                <span className='category-side-lib__info-header__title typ-font-huge'>{prog === 'main' ? 'Főkategóriák' : prog === 'sub' ? 'Alkategóriák' : 'Képfájlok'}</span> 
                {prog !== 'main' ?
                <div className='category-side-lib__info-header__holder'>
                    <ButtonCircle className='category-side-lib__info-header__holder__back' onClick={goBack} value={<>&larr;</>}/>
                    <span className='category-side-lib__info-header__holder__path'>{`/${prog === 'sub' ? openedMain.name : openedMain.name + '/' + openedSub.name}`}</span>
                </div>
                : null}
                {prog === 'sub' || prog === 'main' 
                ? <ButtonCircle className='category-side-lib__info-header__set' onClick={clickSet} value={state.inChanging === true ? <>&#x2714;</> : <>&#x2699;</>}/>
                : <><input onChange={FileOnchange} type='file' multiple className='category-side-lib__info-header__file' style={{ display: 'none' }} /><ButtonCircle className='category-side-lib__info-header__set' onClick={triggerFile} style={{ padding: '0.3rem 1.6rem' }} value={<>&#x21ea;</>}/></>}                
            </div>
            {prog !== 'img' ?
            <div className={`category-side-lib__container${prog === 'main' ? ' category-side-lib__container--min' : ''}`}>                
                {state.categories_tho.map((categ, i) => (
                    <div key={i} data-id={categ.id} data-index={i} className='category-side-lib__container__item'>
                        <ButtonCircle className={`category-side-lib__container__item__del${state.inChanging === false ? ' category-side-lib__container__item__del--none' : ''}`} onClick={deleteClick} value={<>&#128465;</>}/>
                        <div className={`category-side-lib__container__item__move${state.inChanging === false ? ' category-side-lib__container__item__move--none' : ''}`}>
                            {i !== 0 ?
                                <ButtonCircle className='category-side-lib__container__item__move__up' onClick={() => moveUp(categ.id, i)} value='▲'/>
                            : null}
                            {i !== state.categories_tho.length - 1 ?
                                <ButtonCircle className='category-side-lib__container__item__move__down' onClick={() => moveDown(categ.id, i)} value='▼'/>
                            : null}                            
                        </div>
                        {state.inChanging === true
                        ? prog === 'main'
                        ? <input className='category-side-lib__container__item__name category-side-lib__container__item__name--change' value={categ.name} onChange={changed}/>
                        : <TextBoxAuto name='category' onChangeFunc={changeCategory} datas={state.names} id={categ.id} classNameTextbox='category-side-lib__container__item__name category-side-lib__container__item__name--change' text={categ.name} isCategory={true}/>
                        : <input className='category-side-lib__container__item__name' readOnly value={categ.name}/>}  
                        {state.inChanging === false
                        ? <ButtonCircle className='category-side-lib__container__item__open' onClick={openCategory} value={<>&rarr;</>}/>
                        : null}                                                                      
                    </div>
                ))}
                <div  ></div>
                <ButtonCircle className={`category-side-lib__container__add${state.inChanging === false ? ' category-side-lib__container__add--none' : ''}`} onClick={addCategory} value='+'/>
            </div>
            : 
            <div>
                <ImageSideLib viewImg={viewImg} deleteImage={deleteImage} isActive={isActive} addImage={addImage} images={images} handleFiles={handleFiles}/>
            </div>
            }     
            <LoadingSymbol style={{ display: state.load ? 'block' : 'none' }} />           
        </div>    
    );
}
const mapStateToProps = state => ({
    prog: state.product.prog,
    openedMain: state.product.openedMain,
    openedSub: state.product.openedSub
});
const mapDispatchToProps = dispatch => ({
    openMain: v => dispatch(openMain(v)),
    setProg: v => dispatch(setProg(v)),
    openSub: v => dispatch(openSub(v))
});
export default connect(mapStateToProps, mapDispatchToProps)(CategorySideLib);