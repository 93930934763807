import React from 'react';

const NavbarLine = () => (
    <div className='navbar-line'>
        <ul className='navbar-line__item'>
            <i className='icon-envelope'></i>
            <span className='navbar-line__item__title'>mazsolabox@gmail.com</span>            
        </ul>
    </div>
)
export default NavbarLine;