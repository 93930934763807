import React, { useState, useEffect } from 'react';
import { decodePlain } from '../utils';
import axios from '../axios'
import ButtonCircle from './button-circle';

const PanelInfo = ({ doUpdate, setStateOwn }) => {

    /*
<15 középre félkövér térköz-10>{Információk}
<12 egybe térköz-bal-10>{Elérhetőség:}
<12 egybe dőlt térköz-bal-5>{mazsolabox@gmail.com}
<12 térköz-fent-30 térköz-bal-10 térköz-lent-6>{Leírás:}
<12 térköz-bal-10>{Itt egy nagyon hosszú leírás minden féle cuccliról aztán jön a sor vége itt.}
<12 térköz-bal-10>{De ez így kezelhető egy nagy szövegként. Csak a térközre kell odafigyelni.}
    */

    const INITIAL_STATE = {
        plain: '',
        info: [],
        readable: true
    }

    const [state, setState] = useState(INITIAL_STATE);

    useEffect(() => {
        axios.get('get-info-description.php')
        .then(res => {
            console.log(res);
            const result = decodePlain(res.data.info_description);
            if (result.isOk) setState(s => ({...s, info: result.res, readable: true, plain: res.data.info_description}));
            else setState(s => ({...s, readable: false, plain: res.data.info_description}));            
        });
    },[]);

    const change = e => {
        const result = decodePlain(e.target.value);
        if (result.isOk) setState(s => ({...s, info: result.res, readable: true, plain: e.target.value}));
        else setState(s => ({...s, readable: false, plain: e.target.value}));      
    }   
    
    const back = () => {
        doUpdate();
        setStateOwn(s => ({...s, panelInfo: false}));
    }

    const save = () => {
        document.getElementsByClassName('pnl-info__save-box__save-indi')[0].style.display = 'block';
        const data = new FormData();
        data.append('value', state.plain);
        axios.post('update-info-description.php', data)
        .then(res => {
            document.getElementsByClassName('pnl-info__save-box__save-indi')[0].style.display = 'none';
        });
    }

    return (
        <div className='pnl-info'>
            <ButtonCircle className='pnl-info__back' onClick={back} value={<>&larr;</>}/>
            <div className='pnl-info__save-box'>
                <ButtonCircle className='pnl-info__save-box__save-product' value='Mentés' onClick={save}/>
                <div className='pnl-info__save-box__save-indi'/>
            </div>   
            <div className='pnl-info__col'>
                <textarea className='pnl-info__col__write' value={state.plain} onChange={change}/>
            </div>
            <div className='pnl-info__col'>
                <div className='pnl-info__col__read'>
                    {state.readable ? state.info.map((inf, i) => (
                        <label key={i} style={inf.style}>{inf.text}</label>
                    )) : <label>Hiba!</label>}
                </div>
            </div>
        </div>
    );
}
export default PanelInfo;