import React from "react";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { locationChanged } from "../redux/product/product.actions";

import { splitNumber, encrypt, shorten } from "../utils";
import { resources } from "../axios";

const ItemProduct = ({ triggerProduct, category_id, virtual_id, product, history, locationChanged }) => {

    const click = e => {     
        let _target = e.target;
        while (_target.className !== 'item-product') _target = _target.parentNode;          
        const crypted = encrypt(_target.dataset.id);
        let sh = '?page=product';
        if (category_id !== null) sh += `&&category-id=${category_id}`;
        if (virtual_id !== null) sh += `&&virtual-id=${virtual_id}`;
        //history.replace({ pathname: '/', search: `${sh}&&product-id=${crypted}` });
        history.push(`${sh}&&product-id=${crypted}`);
        locationChanged();
        triggerProduct();
    }

    return (
        <div data-id={product.id} onClick={click} className='item-product'>
            <div className='item-product__box'>
                <img className='item-product__box__image' alt={product.image} src={resources + product.image}/>
            </div>            
            <span className='item-product__name'>{shorten(product.name, 27)}</span>            
            <span className='item-product__price'>{`${splitNumber(product.price)} Ft`}</span>
        </div>
    );
}
const mapDispatchToProps = dispatch => ({
    locationChanged: () => dispatch(locationChanged())
});
export default withRouter(connect(null, mapDispatchToProps)(ItemProduct));