import React from 'react';
import { resources } from '../axios';

const ProductPreview = ({ switchImage, deleteImage, images }) => {   

    let STYLE = null;

    const mouseDown = e => {        
        let _target = getImageBox(e);
        STYLE = _target.style;
    }
    
    const drag = e => {        
        let _target = getImageBox(e);
        const X = e.clientX;
        const Y = e.clientY;
        const newX = Math.round(X - _target.offsetWidth / 2);
        const newY = Math.round(Y - _target.offsetHeight / 2);        
        if (_target.style.position !== 'absolute') {
            _target.style.position = 'absolute';
            let dub = document.getElementsByClassName('product-preview__table__box-dub')[Number(_target.dataset.idx)];
            if (Number(_target.dataset.idx) === 0) dub.style.height = '11.75vh';
            else if (Number(_target.dataset.idx) !== images.length - 1) dub.style.height = '11.15vh';            
            dub.className = 'product-preview__table__box-dub product-preview__table__box-dub--active';            
            dub.style.width = `${document.getElementsByClassName('product-preview__table__box')[Number(_target.dataset.idx)].offsetWidth}px`;
        }
        if (newX !== _target.style.left.substr(0, _target.style.left.length - 2)) _target.style.left = `${newX}px`;
        if (newY !== _target.style.top.substr(0, _target.style.top.length - 2)) _target.style.top = `${newY}px`;
        let bin = document.getElementsByClassName('image-side-lib__bin')[0];        
        let inX = false;
        let inY = false;        
        if (bin.offsetLeft + bin.offsetWidth >= X && bin.offsetLeft <= X) inX = true;
        if (bin.offsetTop + bin.offsetHeight >= Y && bin.offsetTop <= Y) inY = true;          
        if (bin.className.includes('--active') && newX > 0) {
            if (!inX || !inY) bin.className = 'image-side-lib__bin';
        }
        else {
            if (inX && inY) bin.className += ' image-side-lib__bin--active';
        }                  
    }

    const dragEnd = e => {    
        let _target = getImageBox(e);
        const X = e.clientX;
        const Y = e.clientY; 
        let bin = document.getElementsByClassName('image-side-lib__bin')[0];
        let imgEl = document.getElementsByClassName('product-preview__table__box');  
        for (let i = 0; i < imgEl.length; i++) {
            let inX = false;
            let inY = false;  
            if (imgEl[i].offsetLeft + imgEl[i].offsetWidth >= X && imgEl[i].offsetLeft <= X) inX = true;
            if (imgEl[i].offsetTop + imgEl[i].offsetHeight >= Y && imgEl[i].offsetTop <= Y) inY = true;
            if (inX && inY && imgEl[i].dataset.idx !== _target.dataset.idx) {
                switchImage(Number(_target.dataset.idx), Number(imgEl[i].dataset.idx));
                break;
            }
        }

        if (bin.className.includes('--active')) {                      
            bin.className = 'image-side-lib__bin';            
            deleteImage(images[Number(_target.dataset.idx)].name);
        }

        let dub = document.getElementsByClassName('product-preview__table__box-dub')[Number(_target.dataset.idx)];
        dub.className = 'product-preview__table__box-dub';
        _target.style = STYLE; 
    }

    const getImageBox = e => {
        let _target = e.target;
        while (_target.className !== 'product-preview__table__box' && !_target.className.includes('--active')) {
            _target = _target.parentNode;            
        }
        return _target;
    }

    const click = e => {
        let _target = getImageBox(e);
        switchImage(0, Number(_target.dataset.idx));
    }

    const clickOnView = () => {
        if (window.innerWidth <= 900) {
            deleteImage(images[0].name);
        }
    }

    return (
        <div className={`product-preview${images.length !== 0 ? '' : ' product-preview--empty'}`}>
            <div style={{ display: images.length !== 0 ? 'block' : 'none' }} className='product-preview__table'>
                {images.map((img, i) => {
                    return (
                        <div key={i}>
                            <div data-idx={i} className={`product-preview__table__box${i === 0 ? ' product-preview__table__box--active' : ''}`} onMouseDown={mouseDown} onDrag={drag} onDragEnd={dragEnd} onClick={click}>
                                <img className='product-preview__table__box__image' alt='img' src={resources + img.name}/>
                            </div>
                            <div className='product-preview__table__box-dub'/>
                        </div>
                    );
                })}
            </div>
            <div style={{ display: images.length !== 0 ? 'block' : 'none' }} className='product-preview__box' onClick={clickOnView}>
                <img className='product-preview__box__image-view' alt='img-view' src={images.length !== 0 ? resources + images[0].name : ''}/>
            </div> 
            <div style={{ display: images.length !== 0 ? 'none' : 'block' }} className='product-preview__layer'/>           
        </div>
    );
}
export default ProductPreview;