import { ProductActionTypes } from "./product.types";

export const setProg = v => ({
    type: ProductActionTypes.SET_PROG,
    value: v
});

export const locationChanged = () => ({
    type: ProductActionTypes.IS_LOCATION_CHANGED    
});

export const logged = v => ({
    type: ProductActionTypes.IS_LOGGED,
    value: v
});

export const openMain = v => ({
    type: ProductActionTypes.OPEN_MAIN,
    value: v
});

export const openSub = v => ({
    type: ProductActionTypes.OPEN_SUB,
    value: v
});


export const clearOpened = () => ({
    type: ProductActionTypes.CLEAR_OPENED
});

export const clearState = () => ({
    type: ProductActionTypes.CLEAR_STATE
});