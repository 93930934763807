import React, { useState, useEffect } from 'react';

import axios from '../axios';
import { decrypt } from '../utils';

import NavbarLine from './navbar-line';
import DirectorySort from './dir-sort';
import PanelSort from './pnl-sort';
import DirectoryProduct from './dir-product';
import Product from './product';
import Information from './information';
import LoadingSign from './loading-sign';

const Main = ({ imageMed, imageMax, product_id, virtual_id, category_id, dofloat }) => {    

    const INITIAL_STATE = {
        trigger_categ: true,
        trigger_product_reco: category_id === null && virtual_id === null && product_id === null ? true : false,
        trigger_product_list: (category_id !== null || virtual_id !== null) && product_id === null ? true : false,
        trigger_product_both: category_id !== null && virtual_id !== null && product_id === null ? true : false,
        trigger_product: product_id === null ? false : true,

        categories: [],
        virtuals: [],
        products: [],

        product: null,
        virtCategs: [],
        images: [],

        titles: []        
    };

    const [state, setState] = useState(INITIAL_STATE);

    useEffect(() => {        
        let newState = state;        
        if (state.trigger_categ) {
            if (!document.getElementsByClassName('loading-sign')[0].className.includes('--active')) {
                if (document.getElementsByClassName('loading-sign')[0].className.includes('--min')) {
                    document.getElementsByClassName('loading-sign')[0].className = 'loading-sign loading-sign--min loading-sign--active';
                }
                else document.getElementsByClassName('loading-sign')[0].className = 'loading-sign loading-sign--active';
            }
            axios.get('get-sort-types.php')            
            .then(res => {
                console.log(res);                
                //setState(s => ({...s, trigger_categ: false, categories: res.data.sorts !== null ? res.data.sorts.categories : [], virtuals: res.data.sorts !== null ? res.data.sorts.virtuals : []}));
                newState.trigger_categ = false;
                newState.categories = res.data.sorts !== null ? res.data.sorts.categories : [];
                newState.virtuals = res.data.sorts !== null ? res.data.sorts.virtuals : [];
                console.log(newState.trigger_product + ' ' + newState.trigger_categ + ' ' + newState.trigger_product_list + ' ' + newState.trigger_product_reco);
                if (!newState.trigger_product_both && !newState.trigger_product_list && !newState.trigger_product_reco && !newState.trigger_categ && !newState.trigger_product) {
                    console.log('set');
                    if (document.getElementsByClassName('loading-sign')[0].className.includes('--min')) {
                        document.getElementsByClassName('loading-sign')[0].className = 'loading-sign loading-sign--min';
                    }
                    else document.getElementsByClassName('loading-sign')[0].className = 'loading-sign';
                    setState({...newState});
                }
            })
        }
        if (state.trigger_product_reco && category_id === null && virtual_id === null) {
            if (!document.getElementsByClassName('loading-sign')[0].className.includes('--active')) {
                if (document.getElementsByClassName('loading-sign')[0].className.includes('--min')) {
                    document.getElementsByClassName('loading-sign')[0].className = 'loading-sign loading-sign--min loading-sign--active';
                }
                else document.getElementsByClassName('loading-sign')[0].className = 'loading-sign loading-sign--active';
            }
            axios.get('get-recommended.php')
            .then(res => {
                console.log(res);                
                //setState(s => ({...s, trigger_product_reco: false, products: res.data.products !== null ? res.data.products : []}));
                newState.trigger_product_reco = false;
                newState.products = res.data.products !== null ? res.data.products : [];
                newState.titles = [];               
                newState.product = null;
                newState.images = [];
                newState.virtCategs = [];
                if (!newState.trigger_product_both && !newState.trigger_product_list && !newState.trigger_product_reco && !newState.trigger_categ && !newState.trigger_product) {
                    //console.log('set');
                    if (document.getElementsByClassName('loading-sign')[0].className.includes('--min')) {
                        document.getElementsByClassName('loading-sign')[0].className = 'loading-sign loading-sign--min';
                    }
                    else document.getElementsByClassName('loading-sign')[0].className = 'loading-sign';
                    setState({...newState});
                }
            })
        }
        else if (((state.trigger_product_list && ((category_id === new URLSearchParams(window.location.search).get('category-id') && category_id !== null && virtual_id === null) || (virtual_id === new URLSearchParams(window.location.search).get('virtual-id') && virtual_id !== null && category_id === null)))
            || (state.trigger_product_both && ((category_id === new URLSearchParams(window.location.search).get('category-id') && category_id !== null) && (virtual_id === new URLSearchParams(window.location.search).get('virtual-id') && virtual_id !== null)))) && new URLSearchParams(window.location.search).has('product-id') === false)
        {
            if (!document.getElementsByClassName('loading-sign')[0].className.includes('--active')) {
                if (document.getElementsByClassName('loading-sign')[0].className.includes('--min')) {
                    document.getElementsByClassName('loading-sign')[0].className = 'loading-sign loading-sign--min loading-sign--active';
                }
                else document.getElementsByClassName('loading-sign')[0].className = 'loading-sign loading-sign--active';
            }
            const data = new FormData();
            //console.log(category_id + ' ' +  new URLSearchParams(window.location.search).get('category-id'));
            console.log(category_id !== null ? decrypt(category_id) : 'null');
            console.log(virtual_id !== null ? decrypt(virtual_id) : 'null');
            if (category_id !== null) data.append('category_id', decrypt(category_id));
            else data.append('category_id', 'null');
            if (virtual_id !== null) data.append('virtual_id', decrypt(virtual_id)); 
            else data.append('virtual_id', 'null');            
            axios.post('list-products.php', data)
            .then(res => {
                console.log(res);               
                //setState(s => ({...s, trigger_product_list: false, products: res.data.products !== null ? res.data.products : []}));
                newState.trigger_product_list = false;
                newState.trigger_product_both = false;
                newState.products = res.data.products !== null ? res.data.products.data : [];               
                newState.titles = res.data.products !== null ? res.data.products.titles : [];
                newState.product = null;
                newState.images = [];
                newState.virtCategs = [];
                if (!newState.trigger_product_both && !newState.trigger_product_list && !newState.trigger_product_reco && !newState.trigger_categ && !newState.trigger_product) {
                    //console.log('set');
                    if (document.getElementsByClassName('loading-sign')[0].className.includes('--min')) {
                        document.getElementsByClassName('loading-sign')[0].className = 'loading-sign loading-sign--min';
                    }
                    else document.getElementsByClassName('loading-sign')[0].className = 'loading-sign';
                    setState({...newState});
                }
            })
        } 
        else if (state.trigger_product && product_id === new URLSearchParams(window.location.search).get('product-id')) {
            if (!document.getElementsByClassName('loading-sign')[0].className.includes('--active')) {
                if (document.getElementsByClassName('loading-sign')[0].className.includes('--min')) {
                    document.getElementsByClassName('loading-sign')[0].className = 'loading-sign loading-sign--min loading-sign--active';
                }
                else document.getElementsByClassName('loading-sign')[0].className = 'loading-sign loading-sign--active';
            }
            const id = decrypt(product_id);       
            const data = new FormData();
            data.append('id', id);
            axios.post('get-one-product.php', data)
            .then(res => { 
            console.log(res);      
            if (res.data.product === undefined) {
                //history.replace({ pathname: '/', search: '' });
                //locationChanged();
            }
            else {
                newState.trigger_product = false;
                newState.products = [];
                newState.titles = [];
                newState.product = res.data.product;
                newState.images = res.data.images;
                newState.virtCategs = res.data.virtCategs;
                //setState(s => ({...s, product: res.data.product, images: res.data.images, virtCategs: res.data.virtCategs}));
                console.log(newState.trigger_product + ' ' + newState.trigger_categ + ' ' + newState.trigger_product_list + ' ' + newState.trigger_product_reco);
                if (!newState.trigger_product_both && !newState.trigger_product_list && !newState.trigger_product_reco && !newState.trigger_categ && !newState.trigger_product) {
                    console.log('set');
                    if (document.getElementsByClassName('loading-sign')[0].className.includes('--min')) {
                        document.getElementsByClassName('loading-sign')[0].className = 'loading-sign loading-sign--min';
                    }
                    else document.getElementsByClassName('loading-sign')[0].className = 'loading-sign';
                    setState({...newState});
                }
            }
        })        
        }

    },[state, state.trigger_categ, state.trigger_product, state.trigger_product_list, state.trigger_product_both, state.trigger_product_reco, product_id, category_id, virtual_id])    

    const triggerProductReco = () => {
        setState(s => ({...s, trigger_product_reco: true}));
    }

    const triggerProductList = () => {
        setState(s => ({...s, trigger_product_list: true}));
    }

    const triggerProductBoth = () => {
        setState(s => ({...s, trigger_product_both: true}));
    }

    const triggerProduct = () => {
        setState(s => ({...s, trigger_product: true}));
    }

    return (
        <>           
            <NavbarLine />                       
            <DirectorySort tag='category' triggerProductBoth={triggerProductBoth} triggerProductList={triggerProductList} categories={state.categories} float={dofloat ? 'left' : 'left'} min={dofloat} category_id={category_id}/>
            <PanelSort />
            {new URLSearchParams(window.location.search).get('page') === 'info'
            ? <Information min={dofloat} triggerProductBoth={triggerProductBoth} triggerProduct={triggerProduct} triggerProductReco={triggerProductReco} category_id={category_id} virtual_id={virtual_id} product_id={product_id}/>
            : category_id === new URLSearchParams(window.location.search).get('category-id') && new URLSearchParams(window.location.search).has('product-id') === false
            ? <DirectoryProduct triggerProduct={triggerProduct} triggerProductReco={triggerProductReco} category_id={category_id} virtual_id={virtual_id} titles={state.titles} products={state.products}/>           
            : <Product med={imageMed} min={imageMax} triggerProductBoth={triggerProductBoth} triggerProductList={triggerProductList} triggerProductReco={triggerProductReco} category_id={category_id} virtual_id={virtual_id} product={state.product} images={state.images} virtCategs={state.virtCategs}/>}
            <DirectorySort tag='virtual' triggerProductBoth={triggerProductBoth} triggerProductList={triggerProductList} categories={state.virtuals} float={dofloat ? 'right' : 'left'} min={dofloat} category_id={category_id}/>           
            <LoadingSign />
        </>
    );
}
export default Main;