import React from 'react';

import DragAndDropFile from './drag-n-drop';
import { resources } from '../axios';

const ImageSideLib = ({ viewImg, isActive, addImage, images, handleFiles, deleteImage }) => {   

    let STYLE = null;

    const mouseDown = e => {        
        let _target = getImageBox(e);
        STYLE = _target.style;
    }
    
    const drag = e => {        
        let _target = getImageBox(e);
        const X = e.clientX;
        const Y = e.clientY;
        const newX = Math.round(X - _target.offsetWidth / 2);
        const newY = Math.round(Y - _target.offsetHeight / 2);        
        if (_target.style.position !== 'absolute') _target.style.position = 'absolute';
        if (newX !== _target.style.left.substr(0, _target.style.left.length - 2)) _target.style.left = `${newX}px`;
        if (newY !== _target.style.top.substr(0, _target.style.top.length - 2)) _target.style.top = `${newY}px`;
        let bin = document.getElementsByClassName('image-side-lib__bin')[0];        
        let inX = false;
        let inY = false;        
        if (bin.offsetLeft + bin.offsetWidth >= X && bin.offsetLeft <= X) inX = true;
        if (bin.offsetTop + bin.offsetHeight >= Y && bin.offsetTop <= Y) inY = true;
        if (bin.className.includes('--active')) {
            if (!inX || !inY) bin.className = 'image-side-lib__bin';
        }
        else {
            if (inX && inY) bin.className += ' image-side-lib__bin--active';
        }
    }

    const dragEnd = e => {    
        let _target = getImageBox(e);
        const X = e.clientX;
        const Y = e.clientY; 
        let bin = document.getElementsByClassName('image-side-lib__bin')[0];    
        if (isActive) {
            let preview = document.getElementsByClassName('product-preview')[0];            
            if (preview.offsetLeft + preview.offsetWidth >= X && preview.offsetLeft <= X
                && preview.offsetTop + preview.offsetHeight >= Y && preview.offsetTop <= Y) {                    
                    addImage(Number(_target.dataset.idx));                    
                }
        }   
        
        if (bin.className.includes('--active')) {                      
            bin.className = 'image-side-lib__bin';
            deleteImage(images[Number(_target.dataset.idx)].name);
            console.log(images[Number(_target.dataset.idx)].name);
        }

        _target.style = STYLE; 
    }

    const getImageBox = e => {
        let _target = e.target;
        while (_target.className !== 'drag-n-drop__container__box' && _target.className !== 'drag-n-drop__container__box drag-n-drop__container__box--selected') {
            _target = _target.parentNode;
        }
        return _target;
    }

    const rightClick = e => {
        e.preventDefault();
        console.log('right');
        let _target = getImageBox(e);
        viewImg(Number(_target.dataset.idx));
    }

    const click = i => {
        if (window.innerWidth <= 900 && isActive) {
            addImage(i);
        }
    }

    return (
        <div className='image-side-lib'>
            <DragAndDropFile handleFiles={handleFiles}>
                <div className='drag-n-drop__container'>                        
                    {images.map((img, i) => (                            
                        <div data-idx={i} key={i} className={`drag-n-drop__container__box${img.selected ? ' drag-n-drop__container__box--selected' : ''}`} onMouseDown={mouseDown} onDrag={drag} onDragEnd={dragEnd} onContextMenu={rightClick} onClick={() => click(i)}>                                
                            <img className='drag-n-drop__container__box__img' src={resources + img.name} alt='img' />
                            <span className='drag-n-drop__container__box__name'>{img.name}</span>                                
                        </div>                           
                    ))}
                </div>
            </DragAndDropFile>
            <div className='image-side-lib__bin'>&#128465;</div>            
        </div>
    );
}
export default ImageSideLib;