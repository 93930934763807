import React, { useState, useEffect, useCallback } from "react";
import axios from '../axios'
import ButtonCircle from "./button-circle";

const CategoryVirtualSideLib = ({ doUpdate, setStateOwn }) => {

    const INITIAL_STATE = {
        isName : true,
        inChanging: false,
        openedName: null,
        categories: []
    };

    const [state, setState] = useState(INITIAL_STATE);

    const getCategories = useCallback(
        () => {
            const data = new FormData();
            if (state.isName) data.append('isName', 1);
            else {
                data.append('isName', 0);    
                data.append('name', state.openedName);
            }    
            axios.post('get-all-virtual-categories.php', data)
            .then(res => {
                console.log(res);
                setState(s => ({...s, categories: s.isName ? res.data.names : res.data.values}));
            })
        },
        [state.openedName, state.isName],
    )

    useEffect(() => {
        getCategories();
    },[state.isName, getCategories])

    const moveUp = (idx) => {
        if (state.isName) {
            setState(s => ({...s, categories: s.categories.map((categ, i) => i === idx || i === idx - 1
                ? i === idx - 1 ? {...s.categories[idx], queue_number_name: s.categories[idx - 1].queue_number_name} : {...s.categories[idx - 1], queue_number_name: s.categories[idx].queue_number_name}
                : categ)}));
        }
        else {
            setState(s => ({...s, categories: s.categories.map((categ, i) => i === idx || i === idx - 1
                ? i === idx - 1 ? {...s.categories[idx], queue_number_value: s.categories[idx - 1].queue_number_value} : {...s.categories[idx - 1], queue_number_value: s.categories[idx].queue_number_value}
                : categ)}));
        }
    }

    const moveDown = (idx) => {
        if (state.isName) {
            setState(s => ({...s, categories: s.categories.map((categ, i) => i === idx || i === idx + 1
                ? i === idx + 1 ? {...s.categories[idx], queue_number_name: s.categories[idx + 1].queue_number_name} : {...s.categories[idx + 1], queue_number_name: s.categories[idx].queue_number_name}
                : categ)}));
        }
        else {
            setState(s => ({...s, categories: s.categories.map((categ, i) => i === idx || i === idx + 1
                ? i === idx + 1 ? {...s.categories[idx], queue_number_value: s.categories[idx + 1].queue_number_value} : {...s.categories[idx + 1], queue_number_value: s.categories[idx].queue_number_value}
                : categ)}));
        }
    }

    const save = () => {
        const data = new FormData();
        if (state.isName) data.append('isName', 1);
        else {
            data.append('isName', 0);                
        }    
        data.append('categs', JSON.stringify(state.categories));
        axios.post('update-virtual-categories.php', data)
        .then(res => {
            console.log(res);
            getCategories();
        })
    }

    const change = () => {
        if (state.inChanging) {
            save();
        }
        setState(s => ({...s, inChanging: !s.inChanging}));
    }

    const open = name => {
        setState(s => ({...s, openedName: name, isName: false, categories: []}));
    }

    const back = () => {
        setState(s => ({...s, openedName: null, isName: true, categories: []}));
    }

    const close = () => {
        doUpdate();
        setStateOwn(s => ({...s, virtualLib: false}));
    }

    return (
        <div className='category-virtual-side-lib'>
            <div className='category-virtual-side-lib__window'>
                <div className='category-virtual-side-lib__window__header'>
                    <span className='category-virtual-side-lib__window__header__title'>{state.isName ? 'Főtulajdonságok' : 'Altulajdonságok'}</span>
                    <div className='category-virtual-side-lib__window__header__info'>
                        <ButtonCircle className='category-virtual-side-lib__window__header__info__back' onClick={state.isName ? close : back} value={state.isName ? 'X' : <>&larr;</>}/>
                        {state.openedName !== null
                        ? <span className='category-virtual-side-lib__window__header__info__path'>{`/${state.openedName}`}</span>
                        : null}
                        <ButtonCircle className='category-virtual-side-lib__window__header__info__set' onClick={change} value={state.inChanging === true ? <>&#x2714;</> : <>&#x2699;</>}/>
                    </div>                    
                </div>
                <div className='category-virtual-side-lib__window__container'>
                    {state.categories.map((categ, i) => (
                        <div key={i} className='category-virtual-side-lib__window__container__item'>
                            <input className='category-virtual-side-lib__window__container__item__name' type='text' readOnly value={state.isName ? categ.name : categ.value}/>
                            <div className={`category-virtual-side-lib__window__container__item__move${state.inChanging ? '' : ' category-virtual-side-lib__window__container__item__move--none'}`}>
                                {i !== 0 ?
                                    <ButtonCircle className='category-virtual-side-lib__window__container__item__move__up' onClick={() => moveUp(i)} value='▲'/>
                                : null}
                                {i !== state.categories.length - 1 ?
                                    <ButtonCircle className='category-virtual-side-lib__window__container__item__move__down' onClick={() => moveDown(i)} value='▼'/>
                                : null}                            
                            </div>
                            {!state.inChanging && state.isName
                            ? <ButtonCircle className='category-virtual-side-lib__window__container__item__open' onClick={() => open(categ.name)} value={<>&rarr;</>}/>
                            : null}                            
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}
export default CategoryVirtualSideLib;